import "./requests.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  doc,
  updateDoc,
  getDoc,
  getDocs,
  deleteDoc,
  addDoc,
  where,
  collection,
} from "@firebase/firestore";
import { useEffect, useState } from "react";
import { db, storage } from "../../firebase";
import Modal from "react-modal";
import "react-toggle/style.css";
import { DataGrid } from "@mui/x-data-grid";

const Requests = () => {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;
  const [isAdmin, setIsAdmin] = useState(false);
  const [requests, setRequests] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [agencyHolder, setAgencyHolder] = useState([]);

  useEffect(() => {
    setIsAdmin(userData?.admin);
  }, [agency]);

  useEffect(() => {
    if (userData) {
      console.log(userData);
      getRequests();
    }
  }, [isAdmin]);
  useEffect(() => {
    if (isModalOpen) {
      getAllAgencies();
    }
  }, [isModalOpen]);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const resetModal = () => {
    setAgencies(agencyHolder);
  };

  const requestClick = async (agencyName) => {
    await addDoc(collection(db, "requests"), {
      agency: agencyName,
      userId: userData.id,
      userName: userData.name,
    });

    getRequests();
  };

  const getAllAgencies = async () => {
    const colRef = collection(db, "CRM");
    const querySnapshot = await getDocs(colRef);
    const agenciesData = querySnapshot.docs.map((doc) => doc.data());
    setAgencies(agenciesData);
    setAgencyHolder(agenciesData);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    const searchTerm = event.target.elements.search.value;
    // Replace 'CRM' with the actual collection name
    const docRef = doc(db, "CRM", searchTerm);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setAgencies([docSnap.data()]);
    } else {
      console.log("No such document!");
      setAgencies([]);
    }
  };

  const approveDeny = async (flag, params) => {
    if (flag) {
      const userDoc = await getDoc(doc(db, "users", params.row.userId));
      const userDocData = userDoc.data();
      userDocData.secondaryagencys.push(params.row.agency);

      await updateDoc(doc(db, "users", params.row.userId), {
        secondaryagencys: userDocData.secondaryagencys,
      });
    }

    await deleteDoc(doc(db, "requests", params.row.requestid));
    await getRequests();
  };

  const checkColumns = [
    { field: "userName", headerName: "Name", width: 150 },
    { field: "userEmail", headerName: "Email", width: 200 },
    { field: "userPhoneNumber", headerName: "Phone", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 575,
      renderCell: (params) => {
        if (isAdmin) {
          return (
            <div className="cellAction">
              <button
                className="actionButtonApprove"
                onClick={() => approveDeny(true, params)}
              >
                Approve
              </button>
              <button
                className="actionButtonDecline"
                onClick={() => approveDeny(false, params)}
              >
                Decline
              </button>
            </div>
          );
        } else {
          return <div className="cellAction"></div>;
        }
      },
    },
  ];

  const getRequests = async () => {
    console.log(agency);
    const ref = collection(db, "requests");
    let queryRef = [where("agency", "==", agency)]; 
    if (!isAdmin) {
        queryRef.push(where("userId", "==", userData.id));
    }
    const querySnapshot = await getDocs(ref, ...queryRef);
    const requestsData = querySnapshot.docs
        .filter(doc => doc.data().agency === userData.agency) // Filter documents by agency
        .map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
    setRequests(requestsData);
};


  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div className="datatable">
          <div className="datatableTitle">
            Requests{" "}
          </div>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            id="requestModal"
          >
            <h2>Search for a Document</h2>

            <div className="searchArea">
              <form onSubmit={handleSearch}>
                <input
                  name="search"
                  type="text"
                  placeholder="Enter document name"
                />
                <button type="submit">Search</button>
              </form>
              <button onClick={closeModal}>Close</button>
              <button onClick={resetModal}>Reset</button>
            </div>
            <div className="agencies">
              <h2>Agencies</h2>
              <ul>
                {agencies.map((agencyObj) => {
                  const hasRequested = requests.some((request) => {
                    return (
                      request.agency === agencyObj.name &&
                      request.userId === userData.id
                    );
                  });
                  console.log(hasRequested);
                  return (
                    <li key={agencyObj.id} className="agencyItem">
                      {agencyObj.name}{" "}
                      <button
                        className="requestButton"
                        disabled={hasRequested}
                        onClick={() => requestClick(agencyObj.name)}
                      >
                        Request
                      </button>
                    </li>
                  );
                })}
                {agencies.length === 0 ? <p>No Agencies found</p> : <></>}
              </ul>
            </div>
          </Modal>

          <div style={{ height: "100%", width: "100%", position: "relative" }}>
            <DataGrid
              className="datagrid"
              rows={requests}
              columns={checkColumns}
              pageSize={9}
              rowsPerPageOptions={[9]}
              components={{
                NoRowsOverlay: () => null,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requests;