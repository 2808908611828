function generateUniqueId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16).toUpperCase();
    });
}

function newFieldName(prefix) {
    return `${prefix}_${generateUniqueId()}`;
}

function convertSealComponentJson(originalJson) {
    // Object 1: SealNumberComponent
    const sealNumberComponent = {
        key: "SealNumberComponent",
        element: "CustomElement",
        component: {},
        type: "custom",
        forwardRef: true,
        bare: true,
        field_name: newFieldName("sealNumber"),
        name: "Seal Number",
        icon: "fa fa-check",
        label: originalJson.label,
        id: generateUniqueId(),
        text: "Seal Number",
        group_name: null,
        static: null,
        required: false,
        showDescription: null,
        custom: true,
        custom_options: [],
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        inline: null,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canPopulateFromApi: true,
    };

    // Object 2: SealedBrokenComponent
    const sealedBrokenComponent = {
        key: "SealedBrokenComponent",
        element: "CustomElement",
        component: {},
        type: "custom",
        forwardRef: true,
        bare: true,
        name: "SealedBroken",
        icon: originalJson.icon,
        label: "SealedBroken",
        field_name: newFieldName("sealedBroken"),
        id: generateUniqueId(),
        text: "SealedBroken",
        group_name: null,
        static: null,
        required: false,
        showDescription: null,
        custom: true,
        custom_options: [],
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        inline: null,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canPopulateFromApi: true
    };

    // Object 3: ReplaceQuestionComponent
    const replaceQuestionComponent = {
        key: "ReplaceQuestionComponent",
        element: "CustomElement",
        component: {},
        type: "custom",
        forwardRef: true,
        bare: true,
        field_name: newFieldName("replaceQuestion"),
        name: "Replace Question",
        icon: "fa fa-check",
        label: "Replace Question",
        id: generateUniqueId(),
        text: "Replace Question",
        group_name: null,
        static: null,
        required: false,
        showDescription: null,
        custom: true,
        custom_options: [],
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        inline: null,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canPopulateFromApi: true
    };

    // Object 4: SealNumberComponent
    const newSealNumberComponent = {
        key: "NewSealNumberComponent",
        element: "CustomElement",
        component: {},
        type: "custom",
        forwardRef: true,
        bare: true,
        field_name: newFieldName("newSealNumber"),
        name: "New Seal Number",
        icon: "fa fa-check",
        label: "New Seal Number",
        id: generateUniqueId(),
        text: "New Seal Number",
        group_name: null,
        static: null,
        required: false,
        showDescription: null,
        custom: true,
        custom_options: [],
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        inline: null,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canPopulateFromApi: true
    };

    return [sealNumberComponent, sealedBrokenComponent, replaceQuestionComponent, newSealNumberComponent];
}

export function replaceSealComponentIntoParts(jsonArray) {
    for (let i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i].key === "SealComponent") {
            const newObjects = convertSealComponentJson(jsonArray[i]);
            jsonArray.splice(i, 1, ...newObjects);
        }
    }
    return jsonArray;
}

export function mergePartsIntoSealComponent(jsonArray) {
    const mergedArray = [];

    for (let i = 0; i < jsonArray.length; i++) {
        // Check if the current component and the next three components form a valid set
        if (jsonArray[i].key === "SealNumberComponent" &&
            jsonArray[i + 1] && jsonArray[i + 1].key === "SealedBrokenComponent" &&
            jsonArray[i + 2] && jsonArray[i + 2].key === "ReplaceQuestionComponent" &&
            jsonArray[i + 3] && jsonArray[i + 3].key === "NewSealNumberComponent") {

            // Merge the components into a single SealComponent
            const mergedComponent = {
                key: "SealComponent",
                element: "CustomElement",
                component: {},
                type: "custom",
                forwardRef: true,
                bare: true,
                name: "Seal",
                icon: jsonArray[i + 1].icon,
                label: jsonArray[i].label,
                field_name: jsonArray[i].field_name.replace("sealNumber_", "seal_"),
                defaultValue: "Seal",
                id: jsonArray[i].id,
                text: "Seal",
                group_name: null,
                static: null,
                required: false,
                showDescription: null,
                custom: true,
                custom_options: [],
                canHavePageBreakBefore: true,
                canHaveAlternateForm: true,
                canHaveDisplayHorizontal: true,
                inline: null,
                canHaveOptionCorrect: true,
                canHaveOptionValue: true,
                canPopulateFromApi: true,
                dirty: jsonArray[i].dirty
            };

            // Add the merged component to the mergedArray
            mergedArray.push(mergedComponent);

            // Skip the next three components as they are already processed
            i += 3;
        } else {
            // If the current component does not form part of a valid set, just add it to the mergedArray
            mergedArray.push(jsonArray[i]);
        }
    }

    return mergedArray;
}