import "./stationboard.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState, useContext } from "react";
import { getDocs, doc, collection, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const StationBoard = () => {
  const [stationData, setStationData] = useState(null);
  const [stationId, setStationId] = useState(null);
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [superAdmin, setSuperAdmin] = useState(null);

  useEffect(() => {
    setSuperAdmin(userData?.superAdmin);
  }, [userData]);

  const handleEditButtonClick = () => {
    if (isEditing) {
      setEditableData(stationData);
    }
    setIsEditing((prevIsEditing) => !prevIsEditing);
  };

  const handleInputChange = (e) => {
    let changedData;
    if (e.target.name === "when2work" || e.target.name === "when2workapp") {
      console.log("e.target.checked: ", e.target.checked);
      changedData = e.target.checked;
    } else {
      changedData = e.target.value;
    }

    console.log("e.target.value: ", e.target.value);

    if (e.target.name === "alarm") {
      // play sound
      const audio = new Audio(`${e.target.value}`);
      audio.play();
    }

    setEditableData({
      ...editableData,
      [e.target.name]: changedData,
    });

    setIsEditing(true);
  };

  // Save edited data
  const saveEdit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, `${agency} Station Board`, stationId);

      await updateDoc(docRef, editableData);

      setStationData(editableData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  useEffect(() => {
    const getStation = async () => {
      const collectionRef = collection(db, `${agency} Station Board`);
      const querySnapshot = await getDocs(collectionRef);

      const docData = querySnapshot.docs[0]?.data(); // Get first document

      setStationData(docData);
      setStationId(querySnapshot.docs[0]?.id);
      setEditableData(docData);
    };
    getStation();
  }, []);

  return (
    <div className="stationBoard">
      <Sidebar />
      <div className="stationBoardContainer">
        <Navbar />
        <div className="top">
        <h1 style={{ color: 'black' }}>{`${agency} Station Board`}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={stationData?.logoURL ? stationData.logoURL : "Loading..."}
              alt=""
              className="itemImg"
            />
          </div>

          <div className="right">
            <form onSubmit={saveEdit}>
              <div className="formInput">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter name"
                  value={editableData?.name ? editableData.name : "Loading..."}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>
              <div className="formInput">
                <label>Description</label>
                <input
                  type="text"
                  placeholder="Enter description"
                  value={editableData?.description ? editableData.description : "Loading..."}
                  onChange={handleInputChange}
                  name="description"
                />
              </div>
              <div className="formInput">
                <label>Zipcode</label>
                <input
                  type="number"
                  placeholder="Enter zipcode"
                  value={editableData?.zip ? editableData.zip : "Loading..."}
                  onChange={handleInputChange}
                  name="zip"
                />
              </div>
              {superAdmin && (
              <div className="formInput">
                <label>API Key</label>
                <input
                  type="text"
                  placeholder="Enter API Key"
                  value={editableData?.apiKey ? editableData.apiKey : "Loading..."}
                  onChange={handleInputChange}
                  name="apiKey"
                />
              </div>
              )}
              {superAdmin && (
              <div className="formInput">
                <label>When2Work EndPoint URL</label>
                <input
                  type="text"
                  placeholder="https://www3.whentowork.com/cgi-bin/w2wC4.dll/api/AssignedShiftList?start_date="
                  value={editableData?.endPoint ? editableData.endPoint : "https://www3.whentowork.com/cgi-bin/w2wC4.dll/api/AssignedShiftList?start_date="}
                  onChange={handleInputChange}
                  name="endPoint"
                />
              </div>
              )}
              <div className="formInput">
                <label className="toggle">
                  When2Work
                  <Toggle
                    icons={false}
                    checked={!!editableData?.when2work}
                    onChange={handleInputChange}
                    name="when2work"
                  />
                  </label>
              </div>
              <div className="formInput">
              <label>Tones are not avalible via browser. Contact support for Stationboard software to enable tones.</label>
              <FormControl fullWidth>
                  <InputLabel>Alarm Tone</InputLabel>
                  <Select
                      labelId="simple-select-label"
                      label="Alarm Tone"
                      size="small"
                      name="alarm"
                      value={!!editableData?.alarm ? editableData.alarm : "https://commondatastorage.googleapis.com/codeskulptor-assets/Collision8-Bit.ogg"}
                      onChange={handleInputChange}
                  >
                  {/* random sounds i found online */}
                  <MenuItem value={"https://commondatastorage.googleapis.com/codeskulptor-assets/Collision8-Bit.ogg"}>Ladder Defualt</MenuItem>
                  <MenuItem value={"https://commondatastorage.googleapis.com/codeskulptor-assets/week7-bounce.m4a"}>MDT Bell</MenuItem>
                  </Select>
              </FormControl>
              </div>
              <button disabled={!isEditing} type="submit">Save</button>
            </form>
            
            <button disabled={!isEditing} onClick={handleEditButtonClick}>Discard Changes</button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default StationBoard;
