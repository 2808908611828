import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import "./rigcheckSubmissionsList.scss";
import { query, where, onSnapshot } from "firebase/firestore";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

function RigcheckSubmissionsList({ id }) {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;

  const [submissions, setSubmissions] = useState([]);
  const [rigcheckName, setRigcheckName] = useState("");

  const checkColumns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "createdAt", headerName: "Time", width: 300 },
    { field: "failCount", headerName: "Fails", width: 100 },
    {
      field: "action",
      headerName: "Action",
      width: 400,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to= {`/rigchecks/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">Edit</div>
            </Link> */}
            <Link
              to={`/rigchecks/submission/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  function countFails(answerData, form) {
    let failCount = 0;

    answerData.forEach((data) => {
      if (data.name.startsWith("my_input") && data.value.toLowerCase() == 'fail') {
        failCount++;
      }
    });

    return failCount;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        {/* Add other toolbar buttons if needed */}
      </GridToolbarContainer>
    );
  }

  function findName(form, answerData) {
    // find the form element with label "Name"
    const formElementWithName = form.find(
      (element) =>
        element.text && element.text === "Name"
    );
    if (!formElementWithName) {
      console.log('No form element with label "Name" found');
      return null;
    }

    // Retrieve the id from this object
    const formElementId = formElementWithName.id;

    // Find the corresponding answer object in answerData array
    const correspondingAnswer = answerData.find(
      (answer) => answer.id === formElementId
    );

    if (!correspondingAnswer) {
      console.log("No corresponding answer found");
      return null;
    }

    // Retrieve the value field from the found object in formanswers array
    return correspondingAnswer.value;
  }

  useEffect(() => {
    const agencyRigcheckDocRef = doc(
      db,
      `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`,
      id
    );
    getDoc(agencyRigcheckDocRef)
      .then((docSnapshot) => {
        console.log("docSnapshot", docSnapshot);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setRigcheckName(data.name);
          const fetchedRigcheckForm = JSON.parse(data.form);

          const agencyRigcheckAnswersCollectionRef = collection(
            db,
            `RigcheckAnswers/OdtRgRZbYmEnNipNIft7/${agency} Rigchecks Answers`
          );
          const q = query(
            agencyRigcheckAnswersCollectionRef,
            where("rigcheckId", "==", id)
          );

          // Subscribe to the answers collection
          const unsubscribe = onSnapshot(q, (snapshot) => {
            const list = snapshot.docs
              .map((doc) => {
                const answerDocData = { ...doc.data(), id: doc.id };
                // Convert createdAt to a Unix timestamp for sorting
                const timestamp = answerDocData.createdAt.seconds * 1000;
                const formattedDate = new Date(timestamp).toLocaleString(
                  "en-US",
                  { timeZone: "America/New_York" }
                );
                answerDocData.createdAtTimestamp = timestamp;

                // Convert createdAt to a formatted date string
                answerDocData.createdAt = formattedDate;
                answerDocData.failCount = countFails(
                  answerDocData.answerData,
                  fetchedRigcheckForm
                );

                answerDocData.name = findName(
                  fetchedRigcheckForm,
                  answerDocData.answerData
                );

                return answerDocData;
              })
              // Sort by createdAtTimestamp in descending order (most recent first)
              .sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);

            setSubmissions(list);
          });

          return () => unsubscribe();
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [id, db, agency]);

  return (
    <div className="datatable">
      <div className="datatableTitle">{rigcheckName} Rigcheck Submissions</div>
      <DataGrid
        className="datagrid"
        rows={submissions} // Using your checks data directly
        columns={checkColumns} // The columns we defined earlier
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}

export default RigcheckSubmissionsList;
