import React, { useEffect } from "react";
import "./health.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Health = () => {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;

  useEffect(() => {
    // Open the URL in a new tab or window when the component is mounted
    const openUptimeRobotStats = () => {
      window.open("https://stats.uptimerobot.com/DsCcpFcRlU", "_blank");
    };

    openUptimeRobotStats();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div className="top">
          <h1 style={{ color: "black" }}>{`System Health`}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            {/* Content for the left side */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Health;
