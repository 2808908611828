import { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, doc, getDocs, query, getDoc, addDoc, setDoc } from "firebase/firestore";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./qualifications.scss";
import { Link } from "react-router-dom";
import { Add, Create, Delete, PersonAdd, Remove } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import CreateGroup from "./createGroup/createGroup";
import AddUser from "./addUser/addUser";

const ResponseQualifications = () => {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openGroup, setOpenGroup] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const getGroups = async () => {
    const q = query(collection(db, 'Agencies', agency, 'Qualifications'));
    const querySnapshot = await getDocs(q);
    let groups = [];
    querySnapshot.forEach((doc) => {
      groups.push(doc.data());
    });

    // for each group, get users from userids and add to group object.
    for (let i = 0; i < groups.length; i++) {
      let users = [];
      for (let j = 0; j < groups[i].users.length; j++) {
        const userRef = doc(db, 'users', groups[i].users[j]);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          users.push(userData);
        } else {
          console.log("No such document!");
        }
      }
      groups[i].users = users;
    }

    setGroups(groups);
    setLoading(false);
  };

  useEffect(() => {
    getGroups();
  }, [agency]);

  const handleOpenGroup = () => setOpenGroup(true);
  const handleOpenAddUser = (group) => {
    setSelectedGroup(group);
    console.log("Selected Group:", group);
    setOpenAddUser(true);
  };

  const removeUserFromGroup = async (userId, groupName) => {
    const group = groups.find((g) => g.name === groupName);
    const updatedUsers = group.users.filter((u) => u.id !== userId);
    const groupRef = doc(db, 'Agencies', agency, 'Qualifications', groupName);
    await setDoc(groupRef, { users: updatedUsers.map((u) => u.id) }, { merge: true });
    setGroups(groups.map((g) =>
      g.name === groupName ? { ...g, users: updatedUsers } : g
    ));

    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();
    const updatedGroups = userData.doclevel.filter((g) => g !== groupName);
    

  };

  const updateUsersInGroup = async (userId, groupName) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);
      const user = userSnap.data();
  
      setGroups((currentGroups) => currentGroups.map((group) => {
        if (group.name === groupName) {
          const updatedUsers = [...group.users, user];
          return { ...group, users: updatedUsers };
        }
        return group;
      }));
    } catch (error) {
      console.error("Error updating users in group:", error);
    }
  };

  const getGroupBorderStyle = (groupName) => {
    return groupName === "Admins" ? { border: "1px solid red" } : { border: "1px solid gray" };
  };

  return (
    <div className="groups">
      <Sidebar />
      <div className="groupsContainer">
        <Navbar />
        <div className="container">
          <div className="title">
            Responder Qualifications
            <button className="createButton" onClick={handleOpenGroup}>
              Add New
            </button>
          </div>
          <div className="description">
            These qualification groups will be displayed to dispatch and/or your PSAP center when a responder calls in
          </div>
          <div className="groupsList">
            {loading ? (
              <h1>Loading...</h1>
            ) : (
              groups.map((group) => (
                <div className="group" style={getGroupBorderStyle(group.name)}>
                  <div className="groupTitle"> <span>{group.name}</span> <PersonAdd className="addIcon" onClick={() => handleOpenAddUser(group.name)}/> </div>
                  <div className="groupUsers">
                    {group.users.map((user) => (
                      <div className="groupUser">
                        <div className="userInfo">
                        <img src={user.userImg || 'https://firebasestorage.googleapis.com/v0/b/ladderemsrn.appspot.com/o/photos%2Favatar.png?alt=media&token=8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk'} alt="" className="userImg" /> {user.name}
                        </div>
                        <Delete className="removeIcon" onClick={() => removeUserFromGroup(user.id, group.name)} />
                      </div>
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

    <CreateGroup
      open={openGroup}
      handleClose={() => setOpenGroup(false)}
      agency={agency}
      getGroups={getGroups}
    />

    <AddUser
      open={openAddUser}
      handleClose={() => setOpenAddUser(false)}
      agency={agency}
      group={selectedGroup}
      updateUsersInGroup={updateUsersInGroup}
    />
  </div>
  );
}

export default ResponseQualifications;