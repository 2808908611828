import "./rigchecks.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import ReactDOM from "react-dom";
import "react-form-builder2/dist/app.css";
import AgencyRigchecksList from "../../components/rigcheck/agencyRigcheckList/agencyRigcheckList";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
const Rigchecks = () => {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <AgencyRigchecksList agency={agency} />
      </div>
    </div>
  );
};

export default Rigchecks;
