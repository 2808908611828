import React, { useEffect, useRef, useState } from "react";

export const PassFailComponent = React.forwardRef((props, ref) => {
  const { name, defaultValue, disabled, read_only } = props;
  console.log(props);
  const [selectedOption, setSelectedOption] = useState(
    defaultValue || props.default || ""
  );

  const handleOptionChange = (event) => {
    if (props.disabled) {
      return;
    }
    setSelectedOption(event.target.value);
  };

  const passRef = useRef(null);
  const failRef = useRef(null);

  useEffect(() => {
    // Assign unique IDs when the component mounts
    passRef.current.id = `passOption_${Math.random()
      .toString(36)
      .substr(2, 9)}`;
    failRef.current.id = `failOption_${Math.random()
      .toString(36)
      .substr(2, 9)}`;
  }, []);

  const formatLabel = (label) => {
    return label.replace(/<strong>/g, '').replace(/<\/strong>/g, '').replace(/</g, '&lt;').replace(/>/g, '&gt;');
};


  return (
    <div className="form-group">
      <label className="form-label">
        <span dangerouslySetInnerHTML={{ __html: formatLabel(props.data.label) }}></span>
        {props.data.required && <span className="label-required badge badge-danger">Required</span>}
      </label>
      <div className="custom-control custom-radio-passfail option-inline">
        <input
          ref={passRef}
          className="custom-control-input"
          name={name}
          type="radio"
          value="pass"
          defaultValue={defaultValue}
          checked={selectedOption === "pass"}
          onChange={handleOptionChange}
        />
        <label
          className="custom-control-label-passfail"
          htmlFor={passRef.current && passRef.current.id}
        >
          Pass
        </label>
      </div>
      <div className="custom-control custom-radio-passfail option-inline">
        <input
          ref={failRef}
          className="custom-control-input"
          name={name}
          type="radio"
          value="fail"
          defaultValue={defaultValue}
          checked={selectedOption === "fail"}
          onChange={handleOptionChange}
        />
        <label
          className="custom-control-label-passfail"
          htmlFor={failRef.current && failRef.current.id}
        >
          Fail
        </label>
      </div>
    </div>
  );
});