import "./newWorkorder.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useContext, useEffect, useRef, useState } from "react";
import { collection, doc, getDocs, query, setDoc } from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { workorderInputs as inputs } from "../../newWorkorderFormSource";
import { AuthContext } from "../../context/AuthContext";
import { v4 as uuid } from "uuid";
const SingleWorkorder = () => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;
  const submittedByRef = useRef();

  useEffect(() => {
    inputs.forEach((input) => {
      if (input.type == "select") {
        handleSelectLoad(input).then(() => setData((prev) => ({ ...prev, [input.id]: input.options[0] })));
      } else if (input.id == "submittedBy") {
        setData((prev) => ({ ...prev, submittedBy: userData?.name }));
        submittedByRef.current.value = userData?.name;
      }
    });
  }, [agency]);
  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, image: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === "opendate" || id === "closeddate") {
      setData({ ...data, [id]: new Date(value) });
      return;
    }

    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e) => {
    console.log(data);
    e.preventDefault();
    // if data is not set, prevent form submission
    if (!data.item || !data.opendate || !data.submittedBy) return;
    console.log("Submitting form...");
    data.item = data.item.trimEnd();
    data.id = uuid();
    data.opendate = new Date(data.opendate).toISOString().split("T")[0];
    data.postTime = new Date();
    try {
      console.log("Attempting to add document to Firestore...");
      await setDoc(doc(db, "Workorders", "Agencies", agency, data.id), {
        ...data,
      });
      console.log("Document added successfully!");
      navigate(-1);
    } catch (err) {
      console.error("Error adding document to Firestore:", err);
    }
  };

  const handleSelectLoad = async (input) => {
    if (input.optionsKey == "groups") {
      setLoadingGroups(true);
      const q = query(collection(db, `${agency} Groups`));
      const querySnapshot = await getDocs(q);
      let groups = [];
      querySnapshot.forEach((doc) => {
        groups.push(doc.data());
      });

      input.options = groups.map((group) => group.name);
      setLoadingGroups(false);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>New Workorder</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <div className="boxed-container">
              <img
                src={
                  file
                    ? URL.createObjectURL(file)
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt=""
              />
            </div>
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type === "select" ? (
                    <select
                      id={input.id}
                      disabled={input.disabled}
                      onChange={handleInput}
                    >
                      {loadingGroups ? (
                        <option>Loading...</option>
                      ) : (
                        input.options.map((option) => (
                          <option key={option} value={option}>
                            {option.toUpperCase()}
                          </option>
                        ))
                      )}
                    </select>
                  ) : input.type === "textarea" ? (
                    <textarea
                      disabled={input.disabled}
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      ref={input.id === "submittedBy" ? submittedByRef : null}
                    ></textarea>
                  ) : (
                    <input
                      disabled={input.disabled}
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      ref={input.id === "submittedBy" ? submittedByRef : null}
                    />
                  )}
                </div>
              ))}
              <button
                className="formInput"
                disabled={per !== null && per < 100}
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleWorkorder;
