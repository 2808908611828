import React, { useRef } from 'react';
import { Dialog, DialogTitle, Button } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import "./qrDialog.scss";
import logo from './QRLogo.png';
const QrDialog = (props) => {
    const { open, onClose, id, agency, name } = props;
    console.log(id);

    const handleClose = () => {
        onClose();
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>QR Code</DialogTitle>
            <div id="qr-code">
                <QRCode
                    value={"https://rigchecks.laddersuite.com/" + agency + "/" + id} id="qr" size={450} logoImage={logo} logoWidth={260} logoHeight={50} quietZone={10} removeQrCodeBehindLogo={true} logoPadding={3}
                 />
                 <div id='rigcheck-name'>
                    {name}
                 </div>
            </div>
            <div style={{ margin: '10px' }}>
                <Button onClick={handlePrint}>Print</Button>
                <Button onClick={handleClose}>Close</Button>
            </div>
        </Dialog>
    );
};

export default QrDialog;