import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import RadioIcon from "@mui/icons-material/Radio";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PeopleIcon from "@mui/icons-material/People";
import TvIcon from "@mui/icons-material/Tv";
import ConstructionIcon from "@mui/icons-material/Construction";
import BadgeIcon from '@mui/icons-material/Badge';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useState, useEffect, useRef } from "react";
import logo from "../../components/images/logoblk.png";
import { getAuth, signOut } from "firebase/auth";
import { AuthContext } from "../../context/AuthContext";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Group } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CodeIcon from '@mui/icons-material/Code';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Sidebar = () => {
  const { userData } = useContext(AuthContext);
  // const [superAdmin, setSuperAdmin] = useState(null);
  const [admin, setadmin] = useState(null);
  const [ladderstaff, setladderstaff] = useState(null);
  const [libraryEnabled, setLibraryEnabled] = useState(false);
  const [rigcheckEnabled, setRigchecksEnabled] = useState(false);
  const [stationboardsEnabled, setStationboardsEnabled] = useState(false);
  const [dutycrewsEnabled, setDutycrewsEnabled] = useState(false);
  const [workordersEnabled, setworkordersEnabled] = useState(false);

  const profileLink = `/users/${userData?.id}`;

  useEffect(() => {
    // setSuperAdmin(userData?.superAdmin);
    setladderstaff(userData?.ladderstaff);

    const db = getFirestore();
    if (userData?.ladderstaff) {
      setadmin(true);
    } else {
      getDoc(doc(db, `${userData?.agency} Groups`, "Admins")).then(
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.users.includes(userData?.id)) {
              setadmin(true);
            }
          }
        }
      );
    }

    const settingsDocRef = doc(db, userData?.agency + " Settings", "Services");
    getDoc(settingsDocRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setLibraryEnabled(data.Library);
          setRigchecksEnabled(data.Rigchecks);
          setStationboardsEnabled(data.Stationboards);
          setDutycrewsEnabled(data.Dutycrews);
          setworkordersEnabled(data.workorders);
        }
      })
      .catch((error) => {
        console.error("Error fetching Terryville Settings:", error);
      });
  }, [userData]);

  const { dispatch } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("user");
    signOut(getAuth());

    navigate("/login");
  };
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <img src={logo} alt="Logo" width={135} height={25} />
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          {ladderstaff && (
            <>
              <p className="title">STAFF TOOLS</p>
              <Link to="/crm" style={{ textDecoration: "none" }}>
                <li>
                  <PsychologyOutlinedIcon className="icon" />
                  <span>CRM</span>
                </li>
              </Link>
              <Link to="/radiofeeds" style={{ textDecoration: "none" }}>
                <li>
                  <RadioIcon className="icon" />
                  <span>Radio Feeds</span>
                </li>
              </Link>
            </>
          )}
          <p className="title">APPS</p>
          {admin && (
            <Link to="/users" style={{ textDecoration: "none" }}>
              <li>
                <PersonOutlineIcon className="icon" />
                <span>Users</span>
              </li>
            </Link>
          )}
          {admin && (
            <Link to="/groups" style={{ textDecoration: "none" }}>
              <li>
                <Group className="icon" />
                <span>Groups</span>
              </li>
            </Link>
          )}
          {admin && (
            <Link to="/qualifications" style={{ textDecoration: "none" }}>
              <li>
                <BadgeIcon className="icon" />
                <span>Qualifications</span>
              </li>
            </Link>
          )}
          <Link to="/library" style={{ textDecoration: "none" }}>
            {admin && libraryEnabled && (
              <li>
                <MenuBookIcon className="icon" />
                <span>Library</span>
              </li>
            )}
          </Link>
          {admin && dutycrewsEnabled && (
            <li>
              <PeopleIcon className="icon" />
              <span>Duty Crews</span>
            </li>
          )}
          <Link to="/requests" style={{ textDecoration: "none" }}>
            {admin && (
              <li>
                <NotificationsIcon className="icon" />
                <span>Requests</span>
              </li>
            )}
          </Link>
          <Link to="/rigchecks" style={{ textDecoration: "none" }}>
            {rigcheckEnabled && (
              <li>
                <LocalShippingIcon className="icon" />
                <span>Rig Checks</span>
              </li>
            )}
          </Link>
          <Link to="/station-board" style={{ textDecoration: "none" }}>
            {admin && stationboardsEnabled && (
              <li>
                <TvIcon className="icon" />
                <span>Station Board</span>
              </li>
            )}
          </Link>
          <Link to="/workorders" style={{ textDecoration: "none" }}>
            {admin && workordersEnabled && (
              <li>
                <ConstructionIcon className="icon" />
                <span>Workorders</span>
              </li>
            )}
          </Link>
          {admin && (
            <>
              <p className="title">SERVICE</p>
              <Link to="/health" style={{ textDecoration: "none" }}>
                <li>
                  <SettingsSystemDaydreamOutlinedIcon className="icon" />
                  <span>System Health</span>
                </li>
              </Link>
              <Link to="/changelog" style={{ textDecoration: "none" }}>
                <li>
                  <CodeIcon className="icon" />
                  <span>Change Log</span>
                </li>
              </Link>
             
              <Link to="/settings" style={{ textDecoration: "none" }}>
                <li>
                  <SettingsApplicationsIcon className="icon" />
                  <span>Settings</span>
                </li>
              </Link>
              <Link to="/my-account" style={{ textDecoration: "none" }}>
                <li>
                  <BorderColorIcon className="icon" />
                  <span>Subscription</span>
                </li>
              </Link>
            </>
          )}

          <p className="title">USER</p>
          {admin && (
            <Link to={profileLink} style={{ textDecoration: "none" }}>
              <li>
                <AccountCircleOutlinedIcon className="icon" />
                <span>Profile</span>
              </li>
            </Link>
          )}
          <Link to="/switch-agency" style={{ textDecoration: "none" }}>
            <li>
              <ViewCarouselOutlinedIcon className="icon" />
              <span>Switch Agency</span>
            </li>
          </Link>
          <li>
            <ExitToAppIcon className="icon" />
            <span onClick={handleLogout}>Logout</span>
          </li>
        </ul>
      </div>
      <div>
        <br></br>
      </div>
    </div>
  );
};

export default Sidebar;
