import "./singleAgency.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  doc,
  updateDoc,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
  collection,
} from "@firebase/firestore";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { useRef } from "react";

import Toggle from "react-toggle";
import "react-toggle/style.css";

const SingleAgency = ({ feeds }) => {
  const { userData } = useContext(AuthContext);
  const { agency } = useParams();
  const [file, setFile] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [agencyContractData, setAgencyContractData] = useState(null);
  const [perc, setPerc] = useState(null);
  const textInputRef = useRef(null);
  const [userInput, setUserInput] = useState("");
  const [settings, setSettings] = useState({});
  const [feedList, setFeedList] = useState([]);
  const handleAddFeed = () => {
    setFeedList([...feedList, {}]);
  };
  console.log("feeds: ", feeds);
  useEffect(() => {
    console.log(feedList);
  }, [feedList]);

  const handleUserInput = (e) => {
    let data = editableData;
    // do nothing if trying to edit existing text in the comments, only allow appending to the end of the comments
    if (
      agencyContractData.comments !==
      e.target.value.slice(0, agencyContractData.comments.length)
    ) {
      console.log(editableData.comments);
      // get the character typed
    } else {
      setEditableData({
        ...data,
        ["comments"]: e.target.value,
      });
    }

    setIsEditing(true);
  };

  const handleEditButtonClick = () => {
    if (isEditing) {
      setEditableData(agencyContractData);
    }
    setIsEditing((prevIsEditing) => !prevIsEditing);
  };

  const uploadFile = async (file) => {
    const name = new Date().getTime() + file.name;

    console.log(name);

    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleSettingsChange = async (e) => {
    let data = { ...settings }; // Create a copy of the settings object
    if (
      e.target.name === "callin" ||
      e.target.name === "dutycrews" ||
      e.target.name === "library" ||
      e.target.name === "rigchecks" ||
      e.target.name === "stationboards"
    ) {
      data[e.target.name] = e.target.checked;
    }
    console.log(data);
    setSettings(data);
    setIsEditing(true);
  };

  const updateSettings = async (e) => {
    let docRef = doc(db, agency + " Settings", "Services");

    updateDoc(docRef, {
      Callin: settings.callin,
      Dutycrews: settings.dutycrews,
      Library: settings.library,
      Rigchecks: settings.rigchecks,
      Stationboards: settings.stationboards,
    });

    docRef = doc(db, "Agencies", agency, "Settings", "Services");
    updateDoc(docRef, {
      Callin: settings.callin,
      Dutycrews: settings.dutycrews,
      Library: settings.library,
      Rigchecks: settings.rigchecks,
      Stationboards: settings.stationboards,
    });
  };

  const handleFeedChange = async (e, index) => {
    let { name, value } = e.target;

    // Create a copy of the feed object at the specified index
    const updatedFeed = { ...feedList[index] };
    // Update the corresponding property of the feed object
    if (value === "on" || value === "off") value = e.target.checked;
    updatedFeed[name] = value;

    // Create a new array with the updated feed object at the specified index
    const updatedFeedList = [...feedList];
    updatedFeedList[index] = updatedFeed;

    // Update the feedList state with the new array
    setFeedList(updatedFeedList);

    setIsEditing(true);
  };

  const handleInputChange = async (e) => {
    let changedData = e.target.value;

    console.log("e.target.value: ", e.target.value);
    console.log(e.target.name);
    if (e.target.name === "logo") {
      console.log("e.target.value: ", e.target.value);
      changedData = await uploadFile(e.target.files[0]);
    }

    setEditableData({
      ...editableData,
      [e.target.name]: changedData,
    });

    setIsEditing(true);
  };

  const saveFeedSubmit = async (e) => {
    e.preventDefault();
    try {
      for (let i = 0; i < feedList.length; i++) {
        const feed = feedList[i];

        await setDoc(doc(db, agency + " Scanner", feed.id), {
          ...feed,
        });

        await setDoc(doc(db, "Agencies", agency, "Scanner", feed.id), {
          ...feed,
        });
      }

      setIsEditing(false);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  // Save edited data
  const saveEdit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, `CRM`, agency);
      const startDate = editableData.startDate;
      const endDate = editableData.endDate;
      // convert before saving to firestore
      editableData.startDate = new Date(startDate);
      editableData.endDate = new Date(endDate);

      if (editableData.comments !== agencyContractData.comments) {
        //time format: MM/DD/YYYY HH:MM dont include seconds
        const commentTimestamp = new Date().toLocaleString("en-US", {
          hour12: true,
          hour: "numeric",
          minute: "numeric",
          month: "numeric",
          day: "numeric",
          year: "numeric",
        });

        // slice from the last index of the agencyContractData.comments to the end of the editableData.comments
        let newComment = editableData.comments.slice(
          agencyContractData.comments.length,
          editableData.comments.length
        );

        //if newComment ends with one or more newlines, then remove them from the end of the newComment
        while (newComment.endsWith("\n")) {
          newComment = newComment.slice(0, newComment.length - 1);
        }

        // if newComment starts with one or more newlines, then remove them from the start of the newComment
        while (newComment.startsWith("\n")) {
          newComment = newComment.slice(1, newComment.length);
        }

        const userEmail = userData?.email || "Unknown User";

        // if newComment is empty or just whitespace, then don't add it to the comments
        if (newComment.trim() === "") {
          editableData.comments = `${agencyContractData.comments}`;
        } else {
          editableData.comments = `${agencyContractData.comments}(${commentTimestamp} - ${userEmail})\n\n${newComment}\n\n`;
        }
      }

      await updateDoc(docRef, editableData);
      await updateSettings();
      // convert back to string for display
      editableData.startDate = startDate;
      editableData.endDate = endDate;

      setAgencyContractData(editableData);
      setIsEditing(false);

      setTimeout(() => {
        textInputRef.current.scrollTop = textInputRef.current.scrollHeight;
      }, 10);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  useEffect(() => {
    const getAgencyContract = async () => {
      const docRef = doc(db, `CRM`, agency);

      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();
      docData.startDate = docData.startDate
        .toDate()
        .toISOString()
        .split("T")[0];
      docData.endDate = docData.endDate.toDate().toISOString().split("T")[0];

      setAgencyContractData(docData);
      setEditableData(docData);
      console.log(docData);

      setTimeout(() => {
        textInputRef.current.scrollTop = textInputRef.current.scrollHeight;
      }, 10);
    };

    const getAgencySettings = async () => {
      const docRef = doc(db, agency + " Settings", "Services");

      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();
      let data = {};

      data.callin = docData.Callin;
      data.dutycrews = docData.Dutycrews;
      data.library = docData.Library;
      data.rigchecks = docData.Rigchecks;
      data.stationboards = docData.Stationboards;
      console.log("RUNNING");
      setSettings(data);
    };

    const getRadioFeeds = async () => {
      const docRef = collection(db, agency + " Scanner");
      let tempFeedList = [];
      const docSnap = await getDocs(docRef);
      docSnap.forEach((snap) => {
        console.log(snap);
        const obj = {
          id: snap.id,
          ...snap.data(),
        };

        tempFeedList.push(obj);
      });

      setFeedList(tempFeedList);
    };

    if (feeds) {
      getRadioFeeds();
    } else {
      getAgencyContract();
      getAgencySettings();
    }
  }, []);

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer" hidden={feeds}>
        <Navbar />
        <div className="top">
          <h1 style={{ color: "black" }}>{`${agency} Contract Info`}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                editableData?.logo
                  ? editableData.logo
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={saveEdit}>
              <div className="formInput">
                <label htmlFor="file">
                  Logo: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => handleInputChange(e)}
                  style={{ display: "none" }}
                  name="logo"
                />
              </div>
              <div className="formInput">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="Enter address"
                  value={editableData?.address ? editableData.address : ""}
                  onChange={handleInputChange}
                  name="address"
                />
              </div>
              <div className="formInput">
                <label>PSAP Type</label>
                <input
                  type="text"
                  placeholder="Enter PSAP Type"
                  value={editableData?.psapType ? editableData.psapType : ""}
                  onChange={handleInputChange}
                  name="psapType"
                />
              </div>
              <div className="formInput">
                <label>Rep</label>
                <input
                  type="text"
                  placeholder="Enter contact"
                  value={editableData?.rep ? editableData.rep : ""}
                  onChange={handleInputChange}
                  name="rep"
                />
              </div>
              <div className="formInput">
                <label>Point Of Contact</label>
                <input
                  type="text"
                  placeholder="Enter contact"
                  value={
                    editableData?.pointOfContact
                      ? editableData.pointOfContact
                      : ""
                  }
                  onChange={handleInputChange}
                  name="pointOfContact"
                />
              </div>
              <div className="formInput">
                <label>Phone</label>
                <input
                  type="text"
                  placeholder="Enter Phone Number"
                  value={editableData?.phone ? editableData.phone : ""}
                  onChange={handleInputChange}
                  name="phone"
                />
              </div>
              <div className="formInput formDate">
                <label>Start Date</label>
                <input
                  type="date"
                  value={editableData?.startDate ? editableData.startDate : ""}
                  onChange={handleInputChange}
                  name="startDate"
                />
              </div>
              <div className="formInput formDate">
                <label>Payment Due Date</label>
                <input
                  type="date"
                  value={editableData?.endDate ? editableData.endDate : ""}
                  onChange={handleInputChange}
                  name="endDate"
                />
              </div>
              <div className="formInput formCost">
                <label>Balance Due</label>
                <input
                  type="text"
                  value={editableData?.cost ? editableData.cost : ""}
                  onChange={handleInputChange}
                  name="cost"
                />
              </div>
              <div className="formInput formComments">
                <label>Comments</label>
                <textarea
                  id="comments-text-area"
                  ref={textInputRef}
                  value={editableData?.comments ? editableData.comments : ""}
                  onChange={handleUserInput}
                  name="comments"
                  rows={12} // You can adjust the number of rows to change the height of the textarea
                />
              </div>

              <button disabled={!isEditing} type="submit">
                Save
              </button>
            </form>

            <button disabled={!isEditing} onClick={handleEditButtonClick}>
              Discard Changes
            </button>
            <div id="settings">
              <h3>Services:</h3>
              <label className="toggle">
                Call In
                <Toggle
                  icons={false}
                  name="callin"
                  onChange={handleSettingsChange}
                  checked={settings.callin}
                />
              </label>
              <label className="toggle">
                Duty Crews
                <Toggle
                  icons={false}
                  name="dutycrews"
                  onChange={handleSettingsChange}
                  checked={settings.dutycrews}
                />
              </label>
              <label className="toggle">
                Libary
                <Toggle
                  icons={false}
                  name="library"
                  onChange={handleSettingsChange}
                  checked={settings.library}
                />
              </label>
              <label className="toggle">
                Rigchecks
                <Toggle
                  icons={false}
                  name="rigchecks"
                  onChange={handleSettingsChange}
                  checked={settings.rigchecks}
                />
              </label>
              <label className="toggle">
                Station Boards
                <Toggle
                  icons={false}
                  name="stationboards"
                  onChange={handleSettingsChange}
                  checked={settings.stationboards}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="listContainer" hidden={!feeds}>
        <Navbar />
        <div className="top">
          <h1 style={{ color: "black" }}>{`${agency} Radio Feeds`}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <form onSubmit={saveFeedSubmit} className="feedsForm">
              {feedList.map((feed, index) => {
                return (
                  <div className="feeds">
                    <span className="formInput">
                      <label>Feed Doc ID</label>
                      <input
                        type="text"
                        placeholder="Enter ID"
                        value={feedList[index]?.id ? feedList[index]?.id : ""}
                        onChange={(event) => handleFeedChange(event, index)}
                        name="id"
                      />
                    </span>
                    <span className="feedOptions">
                      <div className="formInput">
                        <label>Feed Name</label>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          value={
                            feedList[index]?.Feedname
                              ? feedList[index]?.Feedname
                              : ""
                          }
                          onChange={(event) => handleFeedChange(event, index)}
                          name="Feedname"
                        />
                      </div>
                      <div className="formInput">
                        <label>Feed URL</label>
                        <input
                          type="text"
                          placeholder="Enter URL"
                          value={
                            feedList[index]?.Feedurl
                              ? feedList[index]?.Feedurl
                              : ""
                          }
                          onChange={(event) => handleFeedChange(event, index)}
                          name="Feedurl"
                        />
                      </div>
                      <div className="formInput">
                        <label>Frequencies</label>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          value={
                            feedList[index]?.Frequencies
                              ? feedList[index]?.Frequencies
                              : ""
                          }
                          onChange={(event) => handleFeedChange(event, index)}
                          name="Frequencies"
                        />
                      </div>
                      <div className="formInput">
                        <label className="toggle">
                          Disabled
                          <Toggle
                            icons={false}
                            name="disabled"
                            onChange={(event) => handleFeedChange(event, index)}
                            checked={feedList[index].disabled}
                          />
                        </label>
                      </div>
                      <div className="formInput">
                        <label className="toggle">
                          Main Feed
                          <Toggle
                            icons={false}
                            name="mainfeed"
                            onChange={(event) => handleFeedChange(event, index)}
                            checked={feedList[index].mainfeed}
                          />
                        </label>
                      </div>
                      <div className="formInput">
                        <label>Metadata</label>
                        <input
                          type="text"
                          placeholder="Enter Metadata"
                          value={
                            feedList[index]?.metadata
                              ? feedList[index]?.metadata
                              : ""
                          }
                          onChange={(event) => handleFeedChange(event, index)}
                          name="metadata"
                        />
                      </div>
                    </span>
                  </div>
                );
              })}
              <button disabled={!isEditing} type="submit">
                Save
              </button>
            </form>
            <button onClick={handleAddFeed}>Add Feed</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleAgency;
