export const agencyColumns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 70,
      renderCell: (params) => {
        const logoUrl = params.row.logo || "default_logo_url_here";
        return (
          <div className="cellWithImg">
            <img className="cellImg" src={logoUrl} alt="logo" />
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Agency",
      width: 150,
    },
    {
      field: "rep",
      headerName: "Rep",
      width: 150,
    },
    {
      field: "pointOfContact",
      headerName: "Point of Contact",
      width: 150,
    },
    {
    field: "phone",
      headerName: "Phone",
      width: 125,
    },
    {
      field: "psapType",
      headerName: "PSAP Type",
      width: 130,
    },
    
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
    }
  ];