import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Onboarding from "./pages/onboarding/Onboardform";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import StationBoard from "./pages/stationboard/StationBoard";
import SwitchAgency from "./pages/switchagency/SwitchAgency";
import New from "./pages/new/New";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { userInputs } from "./formSource";
import { docInputs } from "./docSource";
import "./style/dark.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import Rigchecks from "./pages/rigchecks/Rigchecks";
import Library from "./pages/library/library";
import NewRigcheck from "./pages/newRigcheck/newRigcheck";
import EditRigcheck from "./pages/editRigcheck/editRigcheck";
import { toast, ToastContainer } from "react-toastify";
import NewDoc from "./pages/newDoc/NewDoc";
import EditDoc from "./pages/editDoc/editDoc";
import ViewRigcheckSubmissions from "./pages/viewRigcheckSubmissions/ViewRigcheckSubmissions";
import SingleRigcheckSubmission from "./pages/singleRigcheckSubmission/SingleRigcheckSubmission";
import RigcheckNotifications from "./pages/rigcheckNotifications/RigcheckNotifications";
import Settings from "./pages/settings/Settings";
import Health from "./pages/health/Health";
import Agencies from "./pages/agencies/agencies";
import SingleAgency from "./pages/singleAgency/SingleAgency";
import NewAgency from "./pages/newAgency/NewAgency";
import MyAccount from "./pages/myAccount.jsx/MyAccount";
import Groups from "./pages/groups/groups";
import Workorders from "./pages/workorders/workorders";
import NewWorkorder from "./pages/newWorkorder/NewWorkorder";
import SingleWorkorder from "./pages/singleWorkorder/SingleWorkorder";
import Radiofeeds from "./pages/radiofeeds/Radiofeeds";
import Requests from "./pages/requests/Requests";
import ChangeLog from "./pages/changeLog/changeLog";
import Qualifications from "./pages/responsequlifications/qualifications"
import FullScreenMap from "./components/map/FullScreenMap";
import { getDoc, doc, getDocs, query, where, collection } from "firebase/firestore";
import { db } from "./firebase";
function App() {
  const { darkMode } = useContext(DarkModeContext);

  const { currentUser } = useContext(AuthContext);
  const { userData } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPaymentDue, setIsPaymentDue] = useState(false);

  useEffect(() => {
    const agency = userData?.agency;
    if (agency) {
      const q = query(collection(db, `${agency} Groups`), where('name', '==', "Admins"));
      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.users.includes(userData?.id)) {
            setIsAdmin(true);
          } else {
          }
        });
      });

      getDoc(doc(db, "CRM", agency)).then((doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data.endDate) {
            const dueDate = new Date(data.endDate.toDate());
            const today = new Date();
            // if dueDate is less than today, then payment is due
            if (dueDate < today) {
              setIsPaymentDue(true);
            }
          }
        }
      });
    }
  }, [userData]);
  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  const RequireAdminOrStaff = ({ children }) => {
    console.log(userData, isAdmin);
    return userData && (isAdmin || userData.ladderstaff) ? (
      children
    ) : (
      <Navigate to="/" />
    );
  };

  const RequireStaff = ({ children }) => {
    return userData && userData.ladderstaff ? children : <Navigate to="/" />;
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <div
        style={{
          backgroundColor: "red",
          color: "white",
          textAlign: "center",
          display: isPaymentDue ? "block" : "none",
          width: "100%",
          padding: "10px 0",
        }}
      >
        Your payment is due or past due. Please click on the subscription tab to pay now or call customer service to avoid service interuption.
      </div>

      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route path="users">
              <Route
                index
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <List />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
              <Route
                path=":userId"
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <Single />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <New inputs={userInputs} title="Add New User" />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="groups">
              <Route
                index
                element={
                  <RequireAuth>
                    <Groups />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="qualifications">
              <Route
                index
                element={
                  <RequireAuth>
                  <RequireAdminOrStaff>
                    <Qualifications />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="requests">
              <Route
                index
                element={
                  <RequireAuth>
                    <Requests />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="rigchecks">
              <Route
                index
                element={
                  <RequireAuth>
                    <Rigchecks />
                  </RequireAuth>
                }
              />
              <Route
                path="notification-settings/:rigcheckId"
                element={
                  <RequireAuth>
                    <RigcheckNotifications />
                  </RequireAuth>
                }
              />
              <Route
                path="view/:rigcheckId"
                element={
                  <RequireAuth>
                    <ViewRigcheckSubmissions />
                  </RequireAuth>
                }
              />
              <Route
                path=":rigCheckId"
                element={
                  <RequireAuth>
                    <EditRigcheck />
                  </RequireAuth>
                }
              />
              <Route
                path="submission/:submissionId"
                element={
                  <RequireAuth>
                    <SingleRigcheckSubmission />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewRigcheck />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="library">
              <Route
                index
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <Library />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <NewDoc inputs={docInputs} title="Add New Document" />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
              <Route
                path=":docId"
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <EditDoc inputs={docInputs} title="Edit Document" />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="crm">
              <Route
                index
                element={
                  <RequireAuth>
                    <RequireStaff>
                      <Agencies />
                    </RequireStaff>
                  </RequireAuth>
                }
              />
              <Route
                path=":agency"
                element={
                  <RequireAuth>
                    <RequireStaff>
                      <SingleAgency feeds={false} />
                    </RequireStaff>
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <RequireStaff>
                      <NewAgency />
                    </RequireStaff>
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="radiofeeds">
              <Route
                index
                element={
                  <RequireAuth>
                    <RequireStaff>
                      <Radiofeeds />
                    </RequireStaff>
                  </RequireAuth>
                }
              />
              <Route
                path=":agency"
                element={
                  <RequireAuth>
                    <RequireStaff>
                      <SingleAgency feeds={true} />
                    </RequireStaff>
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <RequireStaff>
                      <NewAgency />
                    </RequireStaff>
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="my-account"
              element={
                <RequireAuth>
                  <RequireAdminOrStaff>
                    <MyAccount />
                  </RequireAdminOrStaff>
                </RequireAuth>
              }
            />
            <Route
              path="settings"
              element={
                <RequireAuth>
                  <RequireAdminOrStaff>
                    <Settings />
                  </RequireAdminOrStaff>
                </RequireAuth>
              }
            />

            <Route
              path="health"
              element={
                <RequireAuth>
                  <RequireAdminOrStaff>
                    <Health />
                  </RequireAdminOrStaff>
                </RequireAuth>
              }
            />
            <Route
              path="changelog"
              element={
                <RequireAuth>
                  <RequireAdminOrStaff>
                    <ChangeLog />
                  </RequireAdminOrStaff>
                </RequireAuth>
              }
            />

            <Route
              path="station-board"
              element={
                <RequireAuth>
                  <RequireAdminOrStaff>
                    <StationBoard />
                  </RequireAdminOrStaff>
                </RequireAuth>
              }
            />
            <Route path="workorders">
              <Route
                index
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <Workorders />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
              <Route
                path=":workorder"
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <SingleWorkorder />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <NewWorkorder />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="switch-agency"
              element={
                <RequireAuth>
                  {/* <RequireAdminOrStaff> */}
                    <SwitchAgency />
                  {/* </RequireAdminOrStaff> */}
                </RequireAuth>
              }
            />
            <Route path="products">
              <Route
                index
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <List />
                    </RequireAdminOrStaff>
                  </RequireAuth>
                }
              />
              <Route
                path=":productId"
                element={
                  <RequireAuth>
                    <RequireAdminOrStaff>
                      <Single />
                    </RequireAdminOrStaff>
                  </RequireAuth>
               }
              />
            </Route>

            <Route
            path="/"
            element={
            <RequireAuth>
            <Home />
            </RequireAuth>
                  }
          />
          <Route path="/fullscreen-map" element={<FullScreenMap />} />

            <Route path="onboarding/Onboardform" element={<Onboarding />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
