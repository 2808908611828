import "./myAccount.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { doc, updateDoc, getDoc } from "@firebase/firestore";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { db, storage, functions } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";

const MyAccount = () => {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency || "";
  const [file, setFile] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [services, setServices] = useState([]);
  const [agencyContractData, setAgencyContractData] = useState(null);
  const [perc, setPerc] = useState(null);
  const textInputRef = useRef(null);
  //keeping this here for now in case we want to let the user edit certain fields later

  //   const handleEditButtonClick = () => {
  //     if (isEditing) {
  //       setEditableData(agencyContractData);
  //     }
  //     setIsEditing((prevIsEditing) => !prevIsEditing);
  //   };

  //   const uploadFile = async (file) => {
  //     const name = new Date().getTime() + file.name;

  //     console.log(name);

  //     return new Promise((resolve, reject) => {
  //       const storageRef = ref(storage, file.name);
  //       const uploadTask = uploadBytesResumable(storageRef, file);

  //       uploadTask.on(
  //         "state_changed",
  //         (snapshot) => {
  //           const progress =
  //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //           console.log("Upload is " + progress + "% done");
  //           setPerc(progress);
  //           switch (snapshot.state) {
  //             case "paused":
  //               console.log("Upload is paused");
  //               break;
  //             case "running":
  //               console.log("Upload is running");
  //               break;
  //             default:
  //               break;
  //           }
  //         },
  //         (error) => {
  //           console.log(error);
  //           reject(error);
  //         },
  //         () => {
  //           getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //             resolve(downloadURL);
  //           });
  //         }
  //       );
  //     });

  //   };

  //   const handleInputChange = async (e) => {
  //     let changedData = e.target.value;

  //     console.log("e.target.value: ", e.target.value);
  //     if (e.target.name === "logo") {
  //       console.log("e.target.value: ", e.target.value);
  //       changedData = await uploadFile(e.target.files[0]);
  //     }

  //     setEditableData({
  //       ...editableData,
  //       [e.target.name]: changedData,
  //     });

  //     setIsEditing(true);
  //   };

  //   // Save edited data
  //   const saveEdit = async (e) => {
  //     e.preventDefault();
  //     try {
  //       const docRef = doc(db, `CRM`, agency);
  //       const startDate = editableData.startDate;
  //       const endDate = editableData.endDate;
  //       // convert before saving to firestore
  //       editableData.startDate = new Date(startDate);
  //       editableData.endDate = new Date(endDate);

  //       if (editableData.comments !== agencyContractData.comments) {
  //         //time format: MM/DD/YYYY HH:MM dont include seconds
  //         const commentTimestamp = new Date().toLocaleString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric', month: 'numeric', day: 'numeric', year: 'numeric' });

  //         // slice from the last index of the agencyContractData.comments to the end of the editableData.comments
  //         let newComment = editableData.comments.slice(agencyContractData.comments.length, editableData.comments.length);

  //         //if newComment ends with one or more newlines, then remove them from the end of the newComment
  //         while (newComment.endsWith('\n')) {
  //           newComment = newComment.slice(0, newComment.length - 1);
  //         }

  //         const userEmail = userData?.email || 'Unknown User';

  //         // if newComment is empty or just whitespace, then don't add it to the comments
  //         if (newComment.trim() === '') {
  //         } else {
  //           editableData.comments = `${agencyContractData.comments}(${commentTimestamp} - ${userEmail})\n\n${newComment}\n\n`;
  //         }
  //       }

  //       await updateDoc(docRef, editableData);

  //       // convert back to string for display
  //       editableData.startDate = startDate;
  //       editableData.endDate = endDate;

  //       setAgencyContractData(editableData);
  //       setIsEditing(false);

  //       setTimeout(() => {
  //         textInputRef.current.scrollTop = textInputRef.current.scrollHeight;
  //       }, 10);
  //     } catch (error) {
  //       console.error("Error updating document:", error);
  //     }
  //   };

  useEffect(() => {
    const getAgencyContract = async () => {
      console.log(agency);
      const docRef = doc(db, `CRM`, agency);

      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docData = docSnap.data();
        console.log(docData);
        if (docData) {
          // docData.startDate = docData.startDate.toDate().toISOString().split('T')[0];
          // docData.endDate = docData.endDate.toDate().toISOString().split('T')[0];

          // format end date to be MM/DD/YYYY
          docData.endDate = docData.endDate
            .toDate()
            .toLocaleDateString("en-US");

          // Get the current date
          let currentDate = new Date();

          // Get the end date
          let endDate = new Date(docData.endDate); // replace with your actual end date

          // Calculate the difference in months
          let diffInMonths = Math.round(
            (endDate - currentDate) / (1000 * 60 * 60 * 24 * 30)
          );

          // Get the "Pay Now" button
          let payNowButton = document.getElementById("payNowButton"); // replace with your actual button id
          console.log(diffInMonths);
          // Disable the button if the end date is more than 3 months away
          if (diffInMonths > 3) {
            payNowButton.disabled = true;
          } else {
            payNowButton.disabled = false;
          }

          setAgencyContractData(docData);
          setEditableData(docData);
          console.log(docData);
        }
      }
    };

    const getServices = async () => {
      const docRef = doc(db, `${agency} Settings`, "Services");

      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();

      let services = [];
      for (const [key, value] of Object.entries(docData)) {
        if (value) {
          services.push(key);
        }
      }
      setServices(services);
    };

    getAgencyContract();
    getServices();
  }, []);

  const openPay = async (event) => {
    event.preventDefault();
    const price = editableData.cost; // Set your price here, its converted to cents in the backend
    document.getElementById("payNowButton").style.display = "none";
    document.getElementById("spinner").style.display = "flex";
    let startDate = new Date(editableData.endDate);
    let endDate = new Date(editableData.endDate);
    endDate.setFullYear(endDate.getFullYear() + 1); // Add 1 year to the end date (for the subscription period

    // Format the dates
    startDate = startDate.toLocaleDateString("en-US");
    endDate = endDate.toLocaleDateString("en-US");
    const customerEmail = userData?.email;
    const url =
      "https://us-central1-ladderemsrn.cloudfunctions.net/createStripeCheckoutLink";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          price,
          startDate,
          endDate,
          agency,
          customerEmail,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const sessionId = data.id;
      console.log("Stripe checkout session ID:", sessionId);
      // Redirect to checkout page
      // Load Stripe.js
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

      document.getElementById("payNowButton").style.display = "block";
      document.getElementById("spinner").style.display = "none";

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer.
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error creating Stripe checkout link:", error);
    }
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div className="top">
          <h1 style={{ color: "black" }}>My Account</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                editableData?.logo
                  ? editableData.logo
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form>
              {/* <div className="formInput">
                <label htmlFor="file">
                  Logo: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => handleInputChange(e)}
                  style={{ display: "none" }}
                  name="logo"
                />
              </div> */}
              <div className="formInput">
                <label>Address</label>
                <input
                  disabled="true"
                  type="text"
                  placeholder="Enter address"
                  value={editableData?.address ? editableData.address : ""}
                  //   onChange={handleInputChange}
                  name="address"
                />
              </div>
              {/* <div className="formInput">
                <label>PSAP Type</label>
                <input
                  disabled='true'
                  type="text"
                  placeholder="Enter PSAP Type"
                  value={editableData?.psapType ? editableData.psapType : ""}
                //   onChange={handleInputChange}
                  name="psapType"
                />
              </div> */}
              <div className="formInput">
                <label>Rep</label>
                <input
                  disabled="true"
                  type="text"
                  placeholder="Enter rep"
                  value={editableData?.rep ? editableData.rep : ""}
                  // onChange={handleInputChange}
                  name="pointOfContact"
                />
              </div>
              {/* <div className="formInput">
                <label>Point Of Contact</label>
                <input
                  disabled='true'
                  type="text"
                  placeholder="Enter contact"
                  value={editableData?.pointOfContact ? editableData.pointOfContact : ""}
                //   onChange={handleInputChange}
                  name="pointOfContact"
                />
              </div> */}
              <div className="formInput">
                <label>Next Payment Due Date</label>
                <input
                  disabled="true"
                  type="string"
                  value={editableData?.endDate ? editableData.endDate : ""}
                  // onChange={handleInputChange}
                  name="endDate"
                />
              </div>

              <div className="formInput">
                <label>Balance</label>
                <input
                  disabled="true"
                  type="string"
                  value={editableData?.cost ? editableData.cost : ""}
                  // onChange={handleInputChange}
                  name="endDate"
                />
                <button
                  className="pay-button"
                  id="payNowButton"
                  onClick={openPay}
                >
                  Pay Now
                </button>
                <div id="spinner" style={{ display: "none" }}>
                  <div class="loader"></div>
                </div>
              </div>

              <div className="formInput formService">
                <label>Enabled Services</label>
                <div
                  // Add styles as needed to make the div look like a disabled input or as per your design
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor: "#f9f9f9",
                    maxHeight: "100px",
                    overflowY: "auto",
                  }}
                >
                  {services ? services.join(", ") : ""}
                </div>
              </div>
              {/* <button disabled={!isEditing} type="submit">Save</button> */}
            </form>

            {/* <button disabled={!isEditing} onClick={handleEditButtonClick}>Discard Changes</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
