export const workorderColumns = [
  {
    field: "item",
    headerName: "Item",
    width: 220,
  },
  {
    field: "opendate",
    headerName: "Reported Date",
    width: 150,
  },
  {
    field: "submittedBy",
    headerName: "Submitted by:",
    width: 150,
  },
  {
    field: "description",
    headerName: "Description",
    width: 300,
  },
];
