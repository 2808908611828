import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "ladderemsrn.firebaseapp.com",
  databaseURL: "https://ladderemsrn-default-rtdb.firebaseio.com",
  projectId: "ladderemsrn",
  storageBucket: "ladderemsrn.appspot.com",
  messagingSenderId: "723883265845",
  appId: "1:723883265845:web:716064da339b18bbd6867c",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const database = getDatabase(app);
