import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import { collection, getDoc, updateDoc, doc } from "firebase/firestore";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./notificationSettings.scss";
import { DataGrid } from "@mui/x-data-grid";

const NotificationSettings = ({ rigcheckId }) => {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [newNotificationName, setNewNotificationName] = useState(""); // Added state for the new notification name
  const [notificationEmails, setNotificationEmails] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  useEffect(() => {
    async function fetchRigcheckNotificationUsers() {
      const agencyRigchecksCollection = doc(
        db,
        `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks/${rigcheckId}`
      );
      const snapshot = await getDoc(agencyRigchecksCollection);
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (data && data.notificationEmails) {
          const emails = data.notificationEmails;
          const list = emails.map((emailObj, index) => ({
            id: index, // Or a unique identifier from your email object, if available
            email: emailObj.email,
            emailForEverySubmission: emailObj.emailForEverySubmission,
            emailForFailSubmission: emailObj.emailForFailSubmission,
          }));

          setNotificationEmails(list);
          console.log("Emails:", list);
        }
        setName(data.name);
        setLoading(false);
      }
    }
    fetchRigcheckNotificationUsers();
  }, [agency, rigcheckId]);

  const handleInputChange = async (e, params) => {
    const { name, checked } = e.target;
    // Reference to the document
    const docRef = doc(
      db,
      `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks/${rigcheckId}`
    );

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const notificationEmails = docSnap.data().notificationEmails;

      // Find the index of the object where the email matches
      const index = notificationEmails.findIndex(
        (emailObj) => emailObj.email === params.row.email
      );

      if (index !== -1) {
        // Make a shallow copy of the array
        const updatedNotificationEmails = [...notificationEmails];

        // Update the specific object at the found index
        updatedNotificationEmails[index] = {
          ...updatedNotificationEmails[index],
          [name]: checked,
        };

        await updateDoc(docRef, {
          notificationEmails: updatedNotificationEmails,
        });
      } else {
        console.log("Email not found in the array");
      }
    } else {
      console.log("Document does not exist");
    }

    // Update the local state
    setNotificationEmails((prev) => {
      const index = prev.findIndex(
        (emailObj) => emailObj.email === params.row.email
      );
      if (index !== -1) {
        const updatedNotificationEmails = [...prev];
        updatedNotificationEmails[index] = {
          ...updatedNotificationEmails[index],
          [name]: checked,
        };
        return updatedNotificationEmails;
      } else {
        console.log("Email not found in the array");
        return prev;
      }
    });

    console.log("Emails:", notificationEmails);
  };

  const handleAddNotification = async () => {
    try {
      const newEmailObj = {
        email: newNotificationName,
        emailForEverySubmission: false,
        emailForFailSubmission: false,
      };
      console.log("New Notification Name:", newNotificationName);
      const updatedEmails = [...notificationEmails, newEmailObj];

      updatedEmails.forEach((emailObj) => {
        delete emailObj.id;
      });

      await updateDoc(
        doc(
          db,
          `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`,
          rigcheckId
        ),
        {
          notificationEmails: updatedEmails,
        }
      );

      // Update the local state to reflect the new email object
      const list = updatedEmails.map((emailObj, index) => ({
        id: index,
        email: emailObj.email,
        emailForEverySubmission: emailObj.emailForEverySubmission,
        emailForFailSubmission: emailObj.emailForFailSubmission,
      }));

      console.log("Emails:", list);
      setNotificationEmails(list);

      // Clear the input field after adding
      setNewNotificationName("");
    } catch (error) {
      console.error("Error adding email:", error);
    }
  };

  // useEffect(() => {
  //   const getRigcheck = async () => {
  //     const docRef = doc(
  //       db,
  //       `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`,
  //       rigcheckId
  //     );
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       console.log("Document data:", docSnap.data());
  //       const data = docSnap.data();
  //       if (data.emailForEverySubmission == undefined) {
  //         data.emailForEverySubmission = false;
  //       }
  //       if (data.emailForFailSubmission == undefined) {
  //         data.emailForFailSubmission = false;
  //       }
  //       setEmailForEverySubmission(data.emailForEverySubmission);
  //       setEmailForFailSubmission(data.emailForFailSubmission);
  //       setName(data.name);
  //       setLoading(false);
  //     } else {
  //       // doc.data() will be undefined in this case
  //       console.log("No such document!");
  //       setLoading(false);
  //     }
  //   };
  //   getRigcheck();
  // }, []);

  const handleRowSelection = (selection) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selection);
  };

  const handleDeleteSelectedRows = async () => {
    try {
      // Filter out the rows that have been selected
      const updatedNotificationEmails = notificationEmails.filter(
        (_, index) => !selectedRowIds.includes(index)
      );

      // Remove the id property from the objects
      updatedNotificationEmails.forEach((emailObj) => {
        delete emailObj.id;
      });

      // Update the database with the new array
      await updateDoc(
        doc(
          db,
          `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`,
          rigcheckId
        ),
        {
          notificationEmails: updatedNotificationEmails,
        }
      );

      // Update the local state to reflect the new email object
      const list = updatedNotificationEmails.map((emailObj, index) => ({
        id: index,
        email: emailObj.email,
        emailForEverySubmission: emailObj.emailForEverySubmission,
        emailForFailSubmission: emailObj.emailForFailSubmission,
      }));

      setNotificationEmails(list);

      setSelectedRowIds([]);
    } catch (error) {
      console.error("Error deleting rows:", error);
    }
  };

  const checkColumns = [
    { field: "email", headerName: "Email", width: 400 },
    {
      field: "emailForEverySubmission",
      headerName: "Email On Every Rigcheck",
      width: 200,
      renderCell: (params) => {
        const emailForEverySubmission = params.row.emailForEverySubmission;
        return (
          <Toggle
            checked={emailForEverySubmission}
            icons={false}
            name="emailForEverySubmission"
            onChange={(e) => handleInputChange(e, params)}
          />
        );
      },
    },
    {
      field: "emailForFailSubmission",
      headerName: "Email On Failed Rigcheck ",
      width: 200,
      renderCell: (params) => {
        const emailForFailSubmission = params.row.emailForFailSubmission;
        return (
          <Toggle
            checked={emailForFailSubmission}
            icons={false}
            name="emailForFailSubmission"
            onChange={(e) => handleInputChange(e, params)}
          />
        );
      },
    },
  ];
  return (
    <div className="padding">
      <div className="title"> {name} Rigcheck Notifications</div>
      <div className="inputContainer">
        <input
          type="text"
          value={newNotificationName}
          placeholder="Enter Email to Notify"
          onChange={(e) => setNewNotificationName(e.target.value)}
        />
        <button onClick={handleAddNotification} style={{ marginRight: "10px" }}>
          Add
        </button>
        <button onClick={handleDeleteSelectedRows}>Delete Selected Rows</button>
      </div>
      <br></br>
      <DataGrid
        className="datagrid"
        rows={notificationEmails}
        columns={checkColumns}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        autoHeight
        onSelectionModelChange={handleRowSelection}
      />
    </div>
  );
};

export default NotificationSettings;
