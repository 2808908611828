import "./settings.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { doc, updateDoc, getDoc} from "@firebase/firestore";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const Settings = () => {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;
  const [file, setFile] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [settingsData, setSettingsData] = useState(null);
  const [perc, setPerc] = useState(null);


  const handleEditButtonClick = () => {
    if (isEditing) {
      setEditableData(settingsData);
    }
    setIsEditing((prevIsEditing) => !prevIsEditing);
  };

  const uploadFile = async (file) => {
    const name = new Date().getTime() + file.name;

    console.log(name);

    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });

  };
  

  const handleInputChange = async (e) => {
    let changedData = e.target.value;

    console.log("e.target.value: ", e.target.value);
    if (e.target.name === "logo") {
      console.log("e.target.value: ", e.target.value);
      changedData = await uploadFile(e.target.files[0]);
    }

    console.log("changedData: ", changedData);

    setEditableData({
      ...editableData,
      [e.target.name]: changedData,
    });

    console.log("editableData: ", editableData);
    setIsEditing(true);
  };

  // Save edited data
  const saveEdit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, `${agency} Settings`, "Info");

      await updateDoc(docRef, editableData);

      setSettingsData(editableData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  useEffect(() => {
    const getSettings = async () => {
      const docRef = doc(db, `${agency} Settings`, "Info");

      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();
      
      setSettingsData(docData);
      setEditableData(docData);
      console.log(docData);
    };
    getSettings();
  }, []);


  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div className="top">
          <h1 style={{ color: 'black' }}>{`Agency Settings`}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                editableData?.logo
                  ? editableData.logo
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={saveEdit}>
              <div className="formInput">
                <label htmlFor="file">
                  Logo: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => handleInputChange(e)}
                  style={{ display: "none" }}
                  name="logo"
                />
              </div>
              <div className="formInput">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="Enter address"
                  value={editableData?.address ? editableData.address : ""}
                  onChange={handleInputChange}
                  name="address"
                />
              </div>
              <button disabled={!isEditing} type="submit">Save</button>
            </form>
            
            <button disabled={!isEditing} onClick={handleEditButtonClick}>Discard Changes</button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
