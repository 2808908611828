import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getDoc , or} from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";

const Datatable = () => {
  const [data, setData] = useState([]);
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;

  useEffect(() => {
    // const fetchData = async () => {
    //   let list = [];
    //   try {
    //     const querySnapshot = await getDocs(collection(db, "users"));
    //     querySnapshot.forEach((doc) => {
    //       list.push({ id: doc.id, ...doc.data() });
    //     });
    //     setData(list);
    //     console.log(list);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };
    // fetchData();

    // LISTEN (REALTIME)
    if (!agency) return;

    let agencyQuery = query(
      collection(db, "users"),
      or(
        where("agency", "==", agency),
        where("secondaryagencys", "array-contains-any", [agency])
      )
    );

    const unsub = onSnapshot(
      agencyQuery,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, [agency]);

  const handleToggleRemove = async (id) => {
    try {
      const userDocRef = doc(db, "users", id);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Check if the user has a "secondaryagencys" array
        if (
          userData.secondaryagencys &&
          Array.isArray(userData.secondaryagencys)
        ) {
          const updatedSecondaryAgencies = userData.secondaryagencys.filter(
            (userAgency) => userAgency !== agency
          );

          // Update the user document with the modified "secondaryagencys" array
          await updateDoc(userDocRef, {
            agency: "No Agency",
            secondaryagencys: updatedSecondaryAgencies,
          });
        } else {
          // If there is no "secondaryagencys" array, only update the agency
          await updateDoc(userDocRef, {
            agency: "No Agency",
          });
        }

        // Update state to reflect the change in agency
        setData((prevData) =>
          prevData.map((item) =>
            item.id === id ? { ...item, agency: "No Agency" } : item
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };



  const handleToggleDisable = async (id, disabled) => {
    console.log(id, disabled);
    try {
      await updateDoc(doc(db, "users", id), {
        disabled: !disabled,
      });
      setData(
        data.map((item) =>
          item.id === id ? { ...item, disabled: !disabled } : item
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  const confirmResetPassword = async (params) => {
    const confirmation = window.confirm(
      "Are you sure you want to reset this password?"
    );
  
    if (confirmation) sendResetPasswordEmail(params.row.email);
  };
  

  const sendResetPasswordEmail = async (email) => {
    try {
      // call firebase function to send reset password email
      const sendResetEmail = await fetch(
        "https://us-central1-ladderemsrn.cloudfunctions.net/sendPasswordResetEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      );
      const response = await sendResetEmail.json();
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/users/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Edit</div>
            </Link>
            <div
              className={`toggleDisableButton ${
                params.row.disabled ? "enable" : "disable"
              }`}
              onClick={() =>
                handleToggleDisable(params.row.id, params.row.disabled)
              }
            >
              {params.row.disabled ? "Enable" : "Disable"}
            </div>
            <div
              className="cellAction"
              onClick={() => handleToggleRemove(params.row.id)}
            >
              <div className="removeuser">Remove</div>
            </div>
            {userData?.ladderstaff && (
              <div
  className="cellAction"
  onClick={() => confirmResetPassword(params)}
>
  <div className="removeuser">Reset Password</div>
</div>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">User Managment</div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;