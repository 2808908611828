import React, { useEffect, useRef, useState } from "react";

// THIS COMPONENT IS JUST USED FOR DISPLAYING ON THE BUILDER PAGE
export const SealComponent = React.forwardRef((props, ref) => {
  const { name, disabled, read_only, onChange, defaultValue } = props;

  // Using a ref to directly interact with the DOM element
  const inputRef = useRef(null);

  useEffect(() => {
    if (ref) {
      ref.current = inputRef.current; // Assigning the DOM element to the forwarded ref
    }
  }, [ref]);

  const formatLabel = (label) => {
    return label.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  const sealedRef = useRef(null);
  const brokenRef = useRef(null);

  return (
    <div className="form-group">
      <label className="form-label">
        <span dangerouslySetInnerHTML={{ __html: formatLabel(props.data.label) }}></span>
        {props.data.required && <span className="label-required badge badge-danger">Required</span>}
      </label>
      <input
        ref={inputRef} // Using the ref here
        type="text"
        className="form-control"
        name={name}
        disabled={disabled}
        readOnly={read_only}
        value={defaultValue} // Controlled component
      />

      {/* <label className="form-label">
        <span dangerouslySetInnerHTML={{ __html: formatLabel(props.data.label) }}></span>
        {props.data.required && <span className="label-required badge badge-danger">Required</span>}
      </label> */}
      <div className="custom-control custom-radio-passfail option-inline">
        <input
          ref={sealedRef}
          className="custom-control-input"
          type="radio"
          value="sealed"
          defaultValue={defaultValue}
        />
        <label
          className="custom-control-label-passfail"
          htmlFor={sealedRef.current && sealedRef.current.id}
        >
          Sealed
        </label>
      </div>
      <div className="custom-control custom-radio-passfail option-inline">
        <input
          ref={brokenRef}
          className="custom-control-input"
          type="radio"
          value="broken"
          defaultValue={defaultValue}
        />
        <label
          className="custom-control-label-passfail"
          htmlFor={brokenRef.current && brokenRef.current.id}
        >
          Broken
        </label>
      </div>

    </div>
  );
});