import { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Autocomplete, TextField } from '@mui/material';
import { db } from "../../../firebase";
import { doc, setDoc, collection, where, query, getDocs, getDoc } from "firebase/firestore";


const AddUser = ({ open, handleClose, updateUsersInGroup, group, agency }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let groupUsers = null;
    if (!group) return;
    const fetchGroupUsers = async () => {
      const groupRef = doc(db, 'Agencies', agency, 'Qualifications', group);
      const groupSnap = await getDoc(groupRef);
      if (groupSnap.exists()) {
        const groupData = groupSnap.data();
        groupUsers = groupData.users;
        console.log("Group Users:", groupUsers);
      } else {
        console.log("No such document!");
      }
    };

    const fetchUsers = async () => {
      const userRef = collection(db, 'users');
      const q =  query(userRef, where('agency', '==', agency));
      const querySnapshot = await getDocs(q);
      const users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });

      // filter out users that are already in the group
      const filteredUsers = users.filter((user) => !groupUsers.includes(user.id));

      // convert the array of objects to an array of strings of user 'name - email'
      const formattedUsers = filteredUsers.map((user) => {
        return { name: `${user.name} - ${user.email}`, id: user.id };
      });
      setUsers(formattedUsers);
    };

    fetchGroupUsers().then(() => {
      fetchUsers();
    });

  }, [agency, group, open]);

  const handleAddUserSubmit = async () => {
    let usersInGroup = null;
    const groupRef = doc(db, 'Agencies', agency, 'Qualifications', group);
    const groupSnap = await getDoc(groupRef);
    if (groupSnap.exists()) {
      const groupData = groupSnap.data();
      usersInGroup = groupData.users;
    } else {
      console.log("No such document!");
    }

    if (selectedUser) {
      const addUserToGroup = async () => {
        const groupRef = doc(db, 'Agencies', agency, 'Qualifications', group);
        const payload = { users: [...usersInGroup, selectedUser.id] };
        await setDoc(groupRef, payload, { merge: true });

        const userRef = doc(db, 'users', selectedUser.id);
        const userSnap = await getDoc(userRef);
        const userData = userSnap.data();
        const updatedGroups = [...userData.doclevel, group];
      }
      addUserToGroup();
      handleClose();
      setSelectedUser(null);
      updateUsersInGroup(selectedUser.id, group);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} >
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="user-search"
          options={users}
          getOptionLabel={(option) => option.name}
          // renderInput={(params) => <input {...params} placeholder="Search users" />}
          renderInput={(params) => <TextField {...params} label="Search Users" variant="standard"/>}
          onChange={(event, value) => setSelectedUser(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAddUserSubmit} disabled={!selectedUser}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUser;