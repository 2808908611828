import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./newRigcheck.scss";
import { Helmet } from "react-helmet";
import Builder from "../../components/rigcheck/builder/builder";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const NewRigcheck = () => {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency || "";

  return (
    <div className="datatable">
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />
          <Builder agency={agency}/>
        </div>
      </div>
    </div>
  );
};

export default NewRigcheck;
