export const agencyInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Ex. Cannyville",
  },
  {
    id: "address",
    label: "Address",
    type: "text",
    placeholder: "Ex. Station 1 HQ",
  },
  {
    id: "psapType",
    label: "PSAP Type",
    type: "text",
    placeholder: "type",
  },
  {
    id: "rep",
    label: "Rep",
    type: "text",
    placeholder: "ex. John Doe",
  },
  {
    id: "pointOfContact",
    label: "Point of Contact",
    type: "text",
    placeholder: "ex. John Doe",
  },
  {
    id: "phone",
    label: "Phone",
    type: "text",
    placeholder: "ex. John Doe",
  },
  {
    id: "startDate",
    label: "Start Date",
    type: "date",
  },
  {
    id: "endDate",
    label: "End Date",
    type: "date",
  },
  {
    id: "paymentduedate",
    label: "Next Payment Due",
    type: "date",
  },
  {
    id: "cost",
    label: "Balance Due",
    type: "text",
    placeholder: "$",
  },
];
