import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import ReactDOM from "react-dom";
import "react-form-builder2/dist/app.css";
import RigcheckSubmissionsList from "../../components/rigcheck/rigcheckSubmissionsList/RigcheckSubmissionsList";
import "./viewRigcheckSubmissions.scss";
import { useParams } from "react-router";

const ViewRigcheckSubmissions = () => {

  const { rigcheckId } = useParams();
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <RigcheckSubmissionsList id={rigcheckId} />
      </div>
    </div>
  );
};

export default ViewRigcheckSubmissions;