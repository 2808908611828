export const userColumns = [
  { field: "name", headerName: "ID", width: 180 },
  {
    field: "user",
    headerName: "User",
    width: 80,
    renderCell: (params) => {
      const userImg = params.row.userImg || "https://firebasestorage.googleapis.com/v0/b/ladderemsrn.appspot.com/o/photos%2Favatar.png?alt=media&token=8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk";

      return (
        <div className="cellWithImg">
          <img className="cellImg" src={userImg} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },
  {
    field: "position",
    headerName: "Position",
    width: 100,
  },
  
];
