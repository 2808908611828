import "./editDoc.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useContext, useEffect, useState } from "react";
import { doc, updateDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";

const EditDoc = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [originalData, setOriginalData] = useState({});
  const [editableData, setEditableData] = useState({});
  const [downloadURL, setDownloadURL] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [per, setPerc] = useState(null);
  const navigate = useNavigate();
  const { docId } = useParams();
  const { userData } = useContext(AuthContext);

  const agency = userData?.agency;

  useEffect(() => {
    const fetchDocData = async () => {
      const docRef = doc(db, agency + " Library", docId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setOriginalData(data);
        setEditableData(data);
        setFileName(data.fileName || "");
      } else {
        console.log("No such document!");
      }
    };

    fetchDocData();
  }, [docId, agency]);

  useEffect(() => {
    if (originalData.url) {
      setDownloadURL(originalData.url);
    }
  }, [originalData]);

  const handleDownload = () => {
    if (downloadURL) {
      window.open(downloadURL, '_blank');
    } else {
      toast.warn("No document available for download.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (!file) return;

    const name = new Date().getTime() + file.name;
    setFileName(file.name);
    const storageRef = ref(storage, "docs/" + name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPerc(progress);
      },
      (error) => {
        console.error("Upload failed", error);
        setUploadSuccess(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setEditableData((prev) => ({
            ...prev,
            url: downloadURL,
            path: "docs/" + name,
          }));
          setUploadSuccess(true);
        });
      }
    );
  }, [file]);

  const handleInput = (e) => {
    const { id, value } = e.target;
    setEditableData({ ...editableData, [id]: value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, agency + " Library", docId), {
        ...editableData,
        timeStamp: serverTimestamp(),
      });

      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const updateFile = (e) => {
    if (e.target.files[0].type === "application/pdf") {
      setFile(e.target.files[0]);
    } else {
      toast.warn("Only PDFs are accepted.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const discardChanges = () => {
    setEditableData(originalData);
    setPerc(null);
    setUploadSuccess(false);
  };

  return (
    <div className="edit">
      <Sidebar />
      <div className="editContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleUpdate}>
              <div className="formInput">
                <label htmlFor="file">Document: <DriveFolderUploadOutlinedIcon className="icon" /></label>
                <input
                  type="file"
                  id="file"
                  onChange={updateFile}
                  style={{ display: "none" }}
                />
                {uploadSuccess ? (
                  <div className="successIcon">
                    <CheckCircleOutlineIcon
                      style={{ color: "green", fontSize: "36px" }}
                    />
                    {fileName}
                  </div>
                ) : (
                  <>
                    {fileName && <div className="fileName">{fileName}</div>}
                  </>
                )}
              </div>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    value={editableData[input.id] || ""}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                  />
                </div>
              ))}
              <div className="buttons">
                <button disabled={(per != null && per < 100) || editableData === originalData} type="submit">
                  Update
                </button>
                <button type="button" onClick={discardChanges} disabled={editableData === originalData}>
                  Discard Changes
                </button>
                <button type="button" onClick={handleDownload} disabled={!downloadURL}>
                  View Document
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDoc;