import React, { useState, useEffect, useCallback, useRef } from "react";
import { GoogleMap, LoadScript, Marker, TrafficLayer, InfoWindow } from "@react-google-maps/api";
import hydrantIcon from "../../Assets/hydrant.png"; 
import engineIcon from "../../Assets/fire-truck.png"; 
import chiefIcon from "../../Assets/default.png"; 
import ambulanceIcon from "../../Assets/ambulance.png"; 
import responderIcon from "../../Assets/default.png"; 

const containerStyle = {
  width: '100%',
  height: '100%'
};

const defaultCenter = {
  lat: 40.8228121,
  lng: -73.1496657
};

const GoogleMapComponent = ({ location, hydrants, vehicles }) => {
  const [mapRegion, setMapRegion] = useState({
    lat: defaultCenter.lat,
    lng: defaultCenter.lng,
    latitudeDelta: 0.01,
    longitudeDelta: 0.01,
  });
  const [hydrantLayerVisible, setHydrantLayerVisible] = useState(true); // State to manage hydrant layer visibility
  const [filteredHydrants, setFilteredHydrants] = useState([]);
  const [selectedHydrant, setSelectedHydrant] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null); 
  const mapRef = useRef(null);

  const mapCenter = location ? { lat: location.lat, lng: location.lng } : defaultCenter;

  const mapOptions = {
    mapTypeId: 'hybrid', 
    disableDefaultUI: true, 
    zoomControl: true, 
    scaleControl: true, 
    streetViewControl: true 
  };

  const isHydrantInRegion = useCallback((hydrant, region) => {
    if (!window.google) return false;
    const { Shape_X, Shape_Y } = hydrant;
    const { lat, lng, latitudeDelta, longitudeDelta } = region;

    return (
      Shape_X >= lng - longitudeDelta / 2 &&
      Shape_X <= lng + longitudeDelta / 2 &&
      Shape_Y >= lat - latitudeDelta / 2 &&
      Shape_Y <= lat + latitudeDelta / 2
    );
  }, []);

  useEffect(() => {
    if (!window.google) return;
    const filterHydrants = () => {
      const filtered = hydrants
        .filter((hydrant) => isHydrantInRegion(hydrant, mapRegion))
        .slice(0, 50); // Limit to the first 50 hydrants
      setFilteredHydrants(filtered);
    };

    filterHydrants();
  }, [mapRegion, hydrants, isHydrantInRegion]);

  const handleRegionChangeComplete = useCallback(() => {
    if (mapRef.current && window.google) {
      const map = mapRef.current;
      const bounds = map.getBounds();
      if (bounds) {
        const center = map.getCenter();
        setMapRegion({
          lat: center.lat(),
          lng: center.lng(),
          latitudeDelta: bounds.toSpan().lat(),
          longitudeDelta: bounds.toSpan().lng(),
        });
      }
    }
  }, []);

  const handleLoad = useCallback((map) => {
    mapRef.current = map;
    handleRegionChangeComplete();
  }, [handleRegionChangeComplete]);

  const handleUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  const getVehicleIcon = (vehicleType) => {
    switch ((vehicleType || "").toLowerCase()) {
      case "engine":
        return engineIcon;
      case "chief":
        return chiefIcon;
      case "ambulance":
        return ambulanceIcon;
      case "responder":
        return responderIcon;
      default:
        return responderIcon; 
    }
  };

  const openFullScreenMap = () => {
    const url = `/fullscreen-map`; // Simply navigate to the full-screen map route
  
    console.log('Attempting to open URL:', url); // Log the URL for debugging
  
    try {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer'); // Open in a new tab or window
      if (newWindow) {
        newWindow.opener = null; // Security: Prevent the new window from accessing the opener
        console.log('Pop-up opened successfully');
      } else {
        console.error('Pop-up blocked or failed to open');
      }
    } catch (error) {
      console.error('Error opening pop-up:', error); // Log any errors
    }
  };
  

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <LoadScript 
        googleMapsApiKey="AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM"
        onLoad={() => console.log('Script loaded successfully')}
        onError={(error) => console.error('Script loading error: ', error)}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={17}
          options={mapOptions}
          onLoad={handleLoad}
          onUnmount={handleUnmount}
          onIdle={handleRegionChangeComplete}
        >
          {location && <Marker position={mapCenter} />}
          {hydrantLayerVisible && filteredHydrants.map((hydrant, index) => (
            <Marker
              key={index}
              position={{ lat: parseFloat(hydrant.Shape_Y), lng: parseFloat(hydrant.Shape_X) }}
              icon={{
                url: hydrantIcon,
                scaledSize: new window.google.maps.Size(30, 30), 
              }}
              onClick={() => setSelectedHydrant(hydrant)}
            />
          ))}
          {selectedHydrant && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedHydrant.Shape_Y),
                lng: parseFloat(selectedHydrant.Shape_X)
              }}
              onCloseClick={() => setSelectedHydrant(null)}
            >
              <div style={{ backgroundColor: '#212c40', color: 'white', padding: '10px', borderRadius: '5px' }}>
                <h4 style={{ margin: 0, color: 'white' }}>Hydrant Info</h4>
                <p style={{ margin: 0 }}><strong>ID:</strong> {selectedHydrant.HYDRANT_ID}</p>
                <p style={{ margin: 0 }}><strong>Number:</strong> {selectedHydrant.HYDRANT_NU}</p>
                <p style={{ margin: 0 }}><strong>Coordinates:</strong> {selectedHydrant.Shape_Y}, {selectedHydrant.Shape_X}</p>
              </div>
            </InfoWindow>
          )}
          {vehicles.map((vehicle, index) => (
            <Marker
              key={index}
              position={{ lat: vehicle.latitude, lng: vehicle.longitude }}
              icon={{
                url: getVehicleIcon(vehicle.vehicletype),
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              onClick={() => setSelectedVehicle(vehicle)}
            />
          ))}
          {selectedVehicle && (
            <InfoWindow
              position={{ lat: selectedVehicle.latitude, lng: selectedVehicle.longitude }}
              onCloseClick={() => setSelectedVehicle(null)}
            >
              <div style={{ backgroundColor: '#212c40', color: 'white', padding: '10px', borderRadius: '5px' }}>
                <h4 style={{ margin: 0, color: 'white' }}>{selectedVehicle.responderName}</h4>
                <p style={{ margin: 0 }}><strong>Type:</strong> {selectedVehicle.vehicletype}</p>
                <p style={{ margin: 0 }}><strong>Last Updated:</strong> {new Date(selectedVehicle.timestamp).toLocaleString()}</p>
              </div>
            </InfoWindow>
          )}
          <TrafficLayer /> 
        </GoogleMap>
      </LoadScript>

      {/* Toggle Button for Hydrant Layer */}
      <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}>
        <button 
          onClick={() => setHydrantLayerVisible(!hydrantLayerVisible)}
          style={{
            padding: '10px 20px',
            backgroundColor: hydrantLayerVisible ? '#d9534f' : '#5bc0de',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          {hydrantLayerVisible ? 'Hide Hydrants' : 'Show Hydrants'}
        </button>
      </div>

      {/* Button to open the map in a new screen */}
      <div style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}>
        <button 
          onClick={openFullScreenMap}
          style={{
            padding: '10px 20px',
            backgroundColor: '#5bc0de',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Fullscreen
        </button>
      </div>
    </div>
  );
};

export default GoogleMapComponent;
