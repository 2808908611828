import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { useParams } from "react-router";
import { isValidNumber, parsePhoneNumber, isPossiblePhoneNumber} from "libphonenumber-js";
import { updateDoc } from "firebase/firestore";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import Chip from '@mui/material/Chip';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

const Single = ( ) => {

  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [editableData, setEditableData] = useState({});
  const { userId } = useParams();

  const [chips, setChips] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleDelete = (chipToDelete) => () => {
    setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
    setEditableData({
      ...editableData,
      doclevel: chips.filter((chip) => chip !== chipToDelete),
    });
    setIsEditing(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault(); // Prevent form submission on enter key
      if (!chips.includes(inputValue.trim())) { // Avoid duplicate chips
        setChips([...chips, inputValue.trim()]);
        setEditableData({
          ...editableData,
          doclevel: [...chips, inputValue.trim()],
        });
        setInputValue(''); // Clear input field after adding chip
        setIsEditing(true);
      }
    }

    if (chips.length && !inputValue.length && event.key === 'Backspace') {
      setChips((chips) => chips.slice(0, chips.length - 1));
      setEditableData({
        ...editableData,
        doclevel: chips.slice(0, chips.length - 1),
      });
      setIsEditing(true);
    }
  };
  

  const formatPhone = (number) => {
    try {
      if (!number) {
        return { formatted: number, isValid: true };
      }
      
      const phoneNumber = parsePhoneNumber(number, 'US');
      if (!isPossiblePhoneNumber(number, 'US')) {
        return { formatted: number, isValid: false };
      }

      return {formatted: phoneNumber.formatNational(), isValid: true};
    } catch (error) {
      console.error('Failed to format the number:', error);
      return { formatted: number, isValid: false };
    }
  }

  useEffect(() => {
    console.log("id: ", userId);
    const getUser = async (userId) => {
      try {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          console.log("Document data:", userDoc.data());
          setUserData(userDoc.data());  // Set user data
          const { formatted, isValid } = formatPhone(userDoc.data().phone);
          if (!isValid) {
            setInvalidPhone(true);
          } else {
            setInvalidPhone(false);
          }
          console.log(invalidPhone)
          setEditableData(userDoc.data());
          setChips(userDoc.data().doclevel);

        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    }

    getUser(userId);
  }
  , [userId]);
  
  const handleEditButtonClick = () => {
    if (isEditing) {
      setEditableData(userData);
    }
    setIsEditing(prevIsEditing => !prevIsEditing);
  }

  const handleInputChange = (e) => {
    let changedData;
    if (e.target.name === "admin" || e.target.name === "OndutyEMS" || e.target.name === "OndutyFire" || e.target.name === "supervisor") {
      changedData = e.target.checked;
    } else if (e.target.name === "doclevel") {
      changedData = e.target.value.split(',').map(value => value.trim());
    } else {
      changedData = e.target.value;
    }
  
    setEditableData({
      ...editableData,
      [e.target.name]: changedData,
    });
  
    setIsEditing(true);
  };
  

  // Save edited data
  const saveEdit = async (e) => {
    e.preventDefault();
    try {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, editableData);
      setUserData(editableData);
      setIsEditing(false);
      const { formatted, isValid } = formatPhone(editableData.phone);
      if (!isValid) {
        setInvalidPhone(true);
      } else {
        setInvalidPhone(false);
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };
  

  const deleteProfilePicture = async () => {
    setEditableData({
      ...editableData,
      userImg: "",
    });
    setIsEditing(true);
  };


  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <h1 style={{ color: 'black' }}>Edit User: {userData ? userData.name : "Loading..."}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                editableData?.userImg
                  ? editableData.userImg
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
              className="itemImg"
            />
            <button className="deleteButton" onClick={deleteProfilePicture}>
              Delete Profile Img
            </button>
          </div>
          <div className="right">
            <form onSubmit={saveEdit}>
              <div className="formInput">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter name"
                  value={editableData?.name ? editableData.name : ""}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>

              <div className="formInput">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Enter email"
                  value={editableData?.email ? editableData.email : ""}
                  onChange={handleInputChange}
                  name="email"
                />
              </div>

              <div className="formInput">
                <label>Phone <span className="warning">{invalidPhone ? "(This number may be invalid)" : ""}</span></label>
                <input
                  type="text"
                  placeholder="Enter phone number"
                  value={editableData?.phone ? editableData.phone : ""}
                  onChange={handleInputChange}
                  name="phone"
                />
              </div>
  
              <div className="formInput">
                <label>Location</label>
                <input
                  type="text"
                  placeholder="Enter location"
                  value={editableData?.location ? editableData.location : ""}
                  onChange={handleInputChange}
                  name="location"
                />
              </div>

              <div className="formInput">
                <label>Position</label>
                <input
                  type="text"
                  placeholder="Enter position"
                  value={editableData?.position ? editableData.position : ""}
                  onChange={handleInputChange}
                  name="position"
                />
              </div>

              {/* <div className="formInput">
                <label>Document Permissions.Caution case sensitive!</label>
                <input
                  placeholder="ex. All,Chiefs,District,EMS"
                  value={editableData?.doclevel ? editableData.doclevel.join(', ') : ''}
                  onChange={handleInputChange}
                  name="doclevel"
                />
              </div> */}
              <div className="formInput">
                <label className="toggle">
                  Rigcheck Admin Access 
                  <Toggle
                    checked={!!editableData?.supervisor}
                    icons={false}
                    name="supervisor"
                    onChange={handleInputChange}
                  />
                </label>    
              </div>
  
              <div className="formInput">
                <label className="toggle">
                  Admin Dashboard Access 
                  <Toggle
                    checked={!!editableData?.admin}
                    icons={false}
                    name="admin"
                    onChange={handleInputChange}
                  />
                </label>
                
              </div>

              <div className="chipInput">
                <label>Document Permissions. <span className="warning">Caution: case sensitive!</span></label>
                <div className="chipInputContainer">
                  {chips.map((chip, index) => (
                    <Chip
                      key={index}
                      label={chip}
                      onDelete={handleDelete(chip)}
                      className="chip"
                    />
                  ))}
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder={chips.length === 0 ? "Type and press 'Enter'" : ''}
                    className="chipInput"
                  />
                </div>
                
              </div>

              {/* <div className="formInput">
              </div> */}
              <button disabled={!isEditing} type="submit">Save</button>
            </form>
            
            <button disabled={!isEditing} onClick={handleEditButtonClick}>Discard Changes</button>

          </div>
        </div>
      </div>
    </div>
  );
  
};

export default Single;
