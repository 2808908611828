import "./SingleWorkorder.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useContext, useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { workorderInputs as inputs } from "../../newWorkorderFormSource";
import { AuthContext } from "../../context/AuthContext";

const SingleWorkorder = () => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const navigate = useNavigate();
  const { workorder } = useParams();
  const { userData } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});
  const agency = userData?.agency;
  useEffect(() => {
    const getWorkorder = async () => {
      console.log(agency, workorder);
      const docRef = doc(db, `Workorders`, "Agencies", agency, workorder);

      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();
      docData.opendate = new Date(docData.opendate).toISOString().split("T")[0];

      setEditableData(docData);
    };
    getWorkorder();
  }, [workorder]);
  useEffect(() => {
    inputs.forEach((input) => {
      if (input.type == "select") {
        handleSelectLoad(input);
      }
    });
  }, [agency]);
  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, logo: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const uploadFile = async (file) => {
    const name = new Date().getTime() + file.name;

    console.log(name);

    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleInput = async (e) => {
    let changedData = e.target.value;

    console.log("e.target.value: ", e.target.value);
    if (e.target.name === "logo") {
      console.log("e.target.value: ", e.target.value);
      changedData = await uploadFile(e.target.files[0]);
      e.target.id = "image";
    }
    editableData[e.target.id] = changedData;
    setEditableData({
      ...editableData,
    });
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    if (!editableData.item || !editableData.opendate) return;
    let data = editableData;
    let revisions = data.revisions || [];
    revisions.push({
      name: userData.name,
      date: new Date(),
    });
    data.revisions = revisions;
    data.item = data.item.trimEnd();
    try {
      console.log("Attempting to add document to Firestore...");
      await setDoc(doc(db, "Workorders", "Agencies", agency, data.id), {
        ...data,
      });
      console.log("Document added successfully!");
      navigate(-1);
    } catch (err) {
      console.error("Error adding document to Firestore:", err);
    }
  };

  const handleSelectLoad = async (input) => {
    if (input.optionsKey == "groups") {
      const q = query(collection(db, `${agency} Groups`));
      const querySnapshot = await getDocs(q);
      let groups = [];
      querySnapshot.forEach((doc) => {
        groups.push(doc.data());
      });

      input.options = groups.map((group) => group.name);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Edit Workorder</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <div className="boxed-container">
              <img
                src={
                  editableData?.image
                    ? editableData.image
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt=""
              />
            </div>
          </div>
          <div className="right">
            <form onSubmit={handleEdit}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => handleInput(e)}
                  style={{ display: "none" }}
                  name="logo"
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type === "select" ? (
                    <select
                      id={input.id}
                      disabled={input.disabled}
                      onChange={handleInput}
                      value={editableData[input.id] || ""}
                    >
                      {input.options.map((option) => (
                        <option key={option} value={option}>
                          {option.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  ) : input.type === "textarea" ? (
                    <textarea
                      disabled={input.disabled}
                      id={input.id}
                      type={input.type}
                      rows={10}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      value={editableData[input.id] || ""}
                    ></textarea>
                  ) : (
                    <input
                      disabled={input.disabled}
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      value={editableData[input.id] || ""}
                    />
                  )}
                </div>
              ))}
              <button
                className="formInput"
                disabled={per !== null && per < 100}
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <br></br>
        <p>Revisions:</p>
        <ul>
          {editableData?.revisions?.map((revision, i) => (
            <li key={i}>
              {revision.name} - {revision.date.toDate().toDateString()}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};



export default SingleWorkorder;
