import "./singleRigcheckSubmission.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "react-form-builder2/dist/app.css";
import SingleSubmittedForm from "../../components/rigcheck/singleSubmittedForm/SingleSubmittedForm";
import { useParams } from "react-router";

const SingleRigcheckSubmission = () => {
    
    const { submissionId } = useParams();

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <SingleSubmittedForm submissionId={submissionId} />
      </div>
    </div>
  );
};

export default SingleRigcheckSubmission;
