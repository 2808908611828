import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./editRigcheck.scss";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import EditBuilder from "../../components/rigcheck/editBuilder/EditBuilder";
import { useParams } from "react-router-dom";

const EditRigcheck = () => {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;
  console.log(agency);
  const { rigCheckId } = useParams();

  return (
    <div className="datatable">
      <div className="list">
        <Sidebar />
        <div className="listContainer">
          <Navbar />
          <EditBuilder rigCheckId={rigCheckId} agency={agency}/>
        </div>
      </div>
    </div>
  );
};

export default EditRigcheck;
