
import React, { useState, useEffect } from "react";
import { collection, doc, getDocs, getDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import "./versionList.scss";

function ChangeLogList({ agency }) {
  const [checks, setChecks] = useState([]);
  const navigate = useNavigate();

  const viewFile = async (id) => {
    const docRef = doc(db, agency + " Library", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const url = data.url;
      window.open(url, '_blank');
    } else {
      console.log("No such document!");
    }
  };

  const checkColumns = [
    { field: "dashboard", headerName: "Version", width: 110 },
    { field: "dashboardChanges", headerName: "Dashboard Changes", width: 900 },
  ];

  async function fetchChangeLog() {
    const agencyLibraryCollection = collection(db, "Changelog");
    const snapshot = await getDocs(agencyLibraryCollection);
    const list = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setChecks(list);
  }

  async function handleDelete(id, filePath) {
    const docRef = doc(db, "Changelog");
    await deleteDoc(docRef)
      .then(() => {
        console.log("Document successfully deleted from Firestore!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });

    // Deleting the file from Firebase Storage
    const fileRef = ref(storage, filePath);
    await deleteObject(fileRef)
      .then(() => {
        console.log("File successfully deleted from Storage!");
      })
      .catch((error) => {
        console.error("Error removing file: ", error);
      });

    // Fetch the updated list after deletion
    fetchChangeLog();
  }

  useEffect(() => {
    fetchChangeLog();
  }, []);

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Change Log
      </div>
      <DataGrid
        className="datagrid"
        rows={checks}
        columns={checkColumns} // The columns we defined earlier
        pageSize={9}
        rowsPerPageOptions={[9]}
        
      />
    </div>
  );
}

export default ChangeLogList;
