import React, { useState, useRef } from 'react';
import './onboarding.scss';
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore"; 
import logo from "./final-logo-white.png";
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';

const libraries = ["places"];
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Onboarding = () => {
  const [formData, setFormData] = useState({
    agency: '',
    agencyAddress: '',
    contactName: '',
    contactEmail: '',
    contactPhone: ''
  });

  const searchBoxRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState, 
      [name]: value
    }));
  };

  const handlePlaceChanged = () => {
    const place = searchBoxRef.current.getPlaces()[0];
    setFormData(prevState => ({
      ...prevState,
      agency: place ? place.name : '',
      agencyAddress: place ? place.formatted_address : ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "Expo"), formData);
      alert("Thank you for your intrest in Ladder. A representive will be reaching out soon!");
      setFormData({
        agency: '',
        agencyAddress: '',
        contactName: '',
        contactEmail: '',
        contactPhone: ''
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={libraries}>
      <div className="onboarding-container">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
        </div>
        <p className="info-text">
            Thank you for your interest in Ladder Suite. Please fill out the form below and a representative will be in contact soon to set up a demo.
          </p>
        <form className="onboarding-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="agency">Agency</label>
            <StandaloneSearchBox
              onLoad={ref => (searchBoxRef.current = ref)}
              onPlacesChanged={handlePlaceChanged}
              options={{
                types: [''],
                componentRestrictions: { country: 'US' }, // restrict to US, change as needed
                fields: ['name', 'formatted_address', 'place_id']
              }}
            >
              <input 
                type="text" 
                id="agency" 
                name="agency" 
                value={formData.agency} 
                onChange={handleChange} 
                placeholder="Search for your Fire Department or Ambulance Company here"
                required 
              />
            </StandaloneSearchBox>
            <input type="hidden" name="agencyAddress" value={formData.agencyAddress} />
          </div>
          <div className="form-group">
            <label htmlFor="contactName">Contact Name</label>
            <input type="text" id="contactName" name="contactName" value={formData.contactName} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="contactEmail">Contact Email</label>
            <input type="email" id="contactEmail" name="contactEmail" value={formData.contactEmail} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="contactPhone">Contact Phone Number</label>
            <input type="tel" id="contactPhone" name="contactPhone" value={formData.contactPhone} onChange={handleChange} required />
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
    </LoadScript>
  );
};

export default Onboarding;