import {
  ElementStore,
  ReactFormGenerator,
  Registry,
} from "react-form-builder2";
import { Helmet } from "react-helmet";
import "./singleSubmittedForm.scss";
import { Button } from "@mui/material";
import { useState } from "react";
import {
  doc,
  addDoc,
  serverTimestamp,
  collection,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { RotatingLines } from "react-loader-spinner";
import { PassFailComponent } from "../builder/passFailComponent";
import { CommentComponent } from "../builder/commentComponent";
import { SealComponent } from "../builder/sealComponent";
import { SealedBrokenComponent } from "../builder/sealedBrokenComponent";
import { ReplaceQuestionComponent } from "../builder/replaceQuestionComponent";
import { SealNumberComponent } from "../builder/sealNumberComponent";
import { NewSealNumberComponent } from "../builder/newSealNumberComponent";

const SingleSubmittedForm = ({ submissionId }) => {
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [formAnswerData, setFormAnswerData] = useState([]);
  const [formId, setFormId] = useState("");
  const [formQuestions, setFormQuestions] = useState([]);
  const [name, setName] = useState("");
  const [mappedResults, setMappedResults] = useState({});
  const [logo, setLogo] = useState("");
  const [address, setAddress] = useState("");

  ElementStore.subscribe((state) => {
    setFormAnswerData(state.data);
  });
  const onPost = (data) => {
    return;
  };

  function replaceNullWithUndefined(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        obj[key] = undefined;
      } else if (typeof obj[key] === "object") {
        replaceNullWithUndefined(obj[key]);
      }
    });

    return obj;
  }

  useEffect(() => {
    const getSubmittedForm = async () => {
      const docRef = doc(
        db,
        `RigcheckAnswers/OdtRgRZbYmEnNipNIft7/${agency} Rigchecks Answers`,
        submissionId
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const updatedForm = replaceNullWithUndefined(data.answerData); // likely not needed
        setFormAnswerData(updatedForm);
        setFormId(data.rigcheckId);
        // setLoading(false);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        // setLoading(false);
      }
    };
    getSubmittedForm();
  }, []);

  // used for the print view
  function mapQuestionsToAnswers(questions, answers) {
    let result = {};
    let labelCount = {};
    for (let question of questions) {
      if (question.element === "Header" || question.element === "Paragraph") {
        // Count the occurrence of each header/paragraph...
        let label = `${question.element} - ${question.text}`;
        labelCount[label] = (labelCount[label] || 0) + 1;
        let labelWithCount = label;
        if (labelCount[label] > 1) {
          labelWithCount += ` (${labelCount[label]})`;
        }
    
        result[labelWithCount] = question.content;
      } else {
       // Process regular questions
      let answer = answers.find(a => a.id === question.id);
        if (answer) {
        // Replace &lt;, &gt;, <em>, </em>, <strong>, and </strong>...
        question.label = question.label.replace(
          /&lt;|&gt;|<em>|<\/em>|<strong>|<\/strong>/g,
          (match) => {
            switch(match) {
              case "&lt;":
                return "<";
              case "&gt;":
                return ">";
              case "<em>":
                return "";
              case "</em>":
                return "";
              case "<strong>":
              case "</strong>":
                return ""; 
              default:
                return match;
            }
          });
  
          // Count the occurrence of each label...
          labelCount[question.label] = (labelCount[question.label] || 0) + 1;
          let labelWithCount = question.label;
          if (labelCount[question.label] > 1) {
            labelWithCount += `/inv(${labelCount[question.label]})/`;
          }

          let value;

          // exclude parts of seal component from print view 
          if (answer.name && (answer.name.startsWith("replaceQuestion") || answer.name.startsWith("newSealNumber"))) {
            // exclude these questions from the results
            if (!answer.value) continue;
          }

          if (answer.name && answer.name.startsWith("checkboxes")) {
            if (Array.isArray(answer.value)) {
              value = answer.value
                .map((key) => {
                  let option = question.options.find(
                    (o) => o.key === key
                  );
                  return option ? option.value : "";
                })
                .join(", ");
            } else {
              console.log("answer.value is not an array:", answer.value);
            }
          } else if (answer.name && answer.name.startsWith("signature")) {
            value = `data:image/png;base64,${answer.value}`;
          } else {
            value = answer.value;
          }

          result[labelWithCount] = value;
        }
      }
    }
  
    return result;
  }

  useEffect(() => {
    if (formId == "") return;
    const getRigcheck = async () => {
      console.log(formId);
      const docRef = doc(
        db,
        `/Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`,
        formId
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const updatedForm = replaceNullWithUndefined(JSON.parse(data.form)); // likely not needed
        setFormQuestions(updatedForm);
        setName(data.name);

        setMappedResults(mapQuestionsToAnswers(updatedForm, formAnswerData));
        console.log(mappedResults);
        setLoading(false);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setLoading(false);
      }
    };
    getRigcheck();

    const getAgencySettings = async () => {
      const docRef = doc(db, `${agency} Settings`, "Info");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setLogo(data.logo);
        setAddress(data.address);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };

    getAgencySettings();
  }, [formId]);

  const renderDataRows = (data) => {
    return Object.entries(data).map(([key, value], index) => {
      // Remove '/inv(...)/' from the key
      const visibleKey = key.replace(/\/inv\(.*?\)\//g, "");

      if (key.startsWith("Header - ")) {
        return (
          <h1
            key={index}
            style={{ marginTop: "20px", marginBottom: "20px", width: "200%" }}
          >
            {value}
          </h1>
        );
      } else if (key.startsWith("Paragraph - ")) {
        return (
          <p
            key={index}
            style={{ marginTop: "10px", marginBottom: "10px", width: "200%" }}
          >
            {value}
          </p>
        );
      } else if (
        typeof value === "string" &&
        value.startsWith("data:image/png;base64,")
      ) {
        return (
          <tr key={index}>
            <th>{visibleKey}</th>
            <td>
              <img src={value} alt={visibleKey} width="200" height="100" />
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={index}>
            <th>{visibleKey}</th>
            <td>{value}</td>
          </tr>
        );
      }
    });
  };

  try {
    Registry.register("PassFailComponent", PassFailComponent);
    Registry.register("CommentComponent", CommentComponent);
    Registry.register("SealComponent", SealComponent);
    Registry.register("SealedBrokenComponent", SealedBrokenComponent);
    Registry.register("ReplaceQuestionComponent", ReplaceQuestionComponent);
    Registry.register("SealNumberComponent", SealNumberComponent);
    Registry.register("NewSealNumberComponent", NewSealNumberComponent);
  } catch (error) {}
  return (
    <div className="builder-padding">
      <Helmet>
        {" "}
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        />{" "}
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.13.0/css/all.css"
        />{" "}
      </Helmet>{" "}
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "100vh" }}
        >
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="background-container">
            <div className="form-generator-container">
              <div className="form-title">{name}</div>
              <ReactFormGenerator
                answer_data={formAnswerData}
                back_action={`rigchecks/view/${formId}`}
                back_name="Back"
                data={formQuestions}
                read_only={true}
                hide_actions={true}
              />
              <Link
                to={`/rigchecks/view/${formId}`}
                style={{ textDecoration: "none" }}
              >
                <div className="btn">Back</div>
              </Link>
              <button className="btn" onClick={() => window.print()}>
                Print Form
              </button>{" "}
              {/* The print button */}
            </div>
          </div>
          <div className="print-view">
            <div className="header">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <div className="address">
                <div className="visible">{address}</div>
              </div>
            </div>
            <div className="form-title">{name}</div>
            <table>
              <tbody>{renderDataRows(mappedResults)}</tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleSubmittedForm;