import "./navbar.scss";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import logo from "../../pages/login/final-logo-white.png"; 
import { getAuth, signOut } from "firebase/auth";


const Navbar = ({ onToggleMute }) => {
  const { dispatch } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const [userImg, setUserImg] = useState("");
  const [isMuted, setIsMuted] = useState(false);
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    setUserImg(userData?.userImg);
  }, [userData]);

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
    onToggleMute(!isMuted);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    signOut(getAuth());

    navigate("/login")
  };

  return (
    <div className="navbar-nav">
      <div className="wrapper-nav">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
          <div className="logo-subtitle">PSAPLINK</div>
        </div>
        <div className="items">
          <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
          <div className="item">
            <ExitToAppIcon
              className="icon"
              onClick={handleLogout}
            />
          </div>
          <div className="item">
            {isMuted ? (
              <NotificationsOffOutlinedIcon
                className="icon"
                onClick={handleToggleMute}
              />
            ) : (
              <NotificationsNoneOutlinedIcon
                className="icon"
                onClick={handleToggleMute}
              />
            )}
          </div>
          <div className="item">
            {userImg ? (
              <img src={userImg} alt="" className="avatar" />
            ) : (
              <img
                src="https://firebasestorage.googleapis.com/v0/b/ladderemsrn.appspot.com/o/photos%2Favatar.png?alt=media&token=8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk8e349a8b-01d2-4e6b-bc9c-fc23482d2230&_gl=1*elqacr*_ga*OTczNjc0NTc5LjE2OTYzNjk"
                alt="Default"
                className="avatar"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
