export const userInputs = [
    {
      id: "Name",
      label: "Name",
      type: "text",
      placeholder: "john Doe",
    },
    {
      id: "position",
      label: "Position",
      type: "text",
      placeholder: "ex. Firefighter",
    },
    {
      id: "email",
      label: "Email",
      type: "mail",
      placeholder: "john_doe@gmail.com",
    },
    {
      id: "phone",
      label: "Phone",
      type: "text",
      placeholder: "+1 234 567 89",
    },
    {
      id: "password",
      label: "Password",
      type: "password",
    },
    {
      id: "location",
      label: "Station",
      type: "text",
      placeholder: "Ex. Station 1 HQ",
    },
    
  ];
  
  