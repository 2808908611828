import "./switchagency.scss";

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState, useContext } from "react";
import { getDocs, doc, collection, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const SwitchAgency = () => {
  const [agencyNames, setAgencyNames] = useState(null);
  const { userData } = useContext(AuthContext);
  const [userAgency, setUserAgency] = useState(null);

  const handleAgencyClick = async (selectedAgency) => {
    if (selectedAgency !== userAgency) {
      await updateUserDataAgency(selectedAgency);
    }
  };

  const updateUserDataAgency = async (selectedAgency) => {
    const userRef = doc(db, "users", userData.id);
    setUserAgency(selectedAgency);
    await updateDoc(userRef, {
      agency: selectedAgency,
    });
  };

  useEffect(() => {
    if (userData) {
      // Assuming "secondaryagencies" is an array field in the user's document
      const secondaryAgencies = userData.secondaryagencys || [];
      setAgencyNames(secondaryAgencies);
    }
  }, [userData]);

  useEffect(() => {
    if (userData) {
      const agency = userData.agency;
      setUserAgency(agency);
    }
  }, [userData]);

  return (
    <div className="switchAgency">
      <Sidebar />
      <div className="switchAgencyContainer">
        <Navbar />
        <div className="container">
          <div className="title">Switch Agency</div>
          <div className="agencyWidgetsContainer">
            {agencyNames &&
              agencyNames.map((agencyName) => (
                <div
                  key={agencyName}
                  className={`agencyWidget ${
                    userAgency === agencyName ? "selected" : ""
                  }`}
                  onClick={() => handleAgencyClick(agencyName)}
                >
                  {agencyName}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchAgency;