import "./workorderTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getDoc } from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import { workorderColumns } from "../../workorderTableSource";

const WorkorderTable = () => {
  const [data, setData] = useState([]);
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;

  const fetchGroupUsers = async () => {
    const groupRef = collection(db, `${agency} Groups`);
    const groupSnap = await getDocs(groupRef);
    let found = false;
    if (groupSnap.size > 0) {
      groupSnap.forEach((doc) => {
        if (doc.data().name === "Workorders") {
          const users = doc.data().users;
          users.forEach((user) => {
            if (user === userData.id) {
              found = true;
            }
          });
        }
      });
    }
    return found;
  };
  useEffect(async () => {
    if (!agency) return;
    const found = await fetchGroupUsers();

    if (!found) return;
    let CRAQuery = query(collection(db, "Workorders", "Agencies", agency));

    const unsub = onSnapshot(
      CRAQuery,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          // convert the start and end date firestore timestamp objects into the string with format "MM/DD/YYYY"
          const docData = doc.data();
          const openDate = new Date(docData.opendate);
          const closedDate = docData.postTime.toDate();
          const openDateString = `${
            openDate.getMonth() + 1
          }/${openDate.getDate()}/${openDate.getFullYear()}`;
          const closedDateString = `${
            closedDate.getMonth() + 1
          }/${closedDate.getDate()}/${closedDate.getFullYear()}`;
          docData.opendate = openDateString;
          docData.closeddate = closedDateString;

          list.push({ id: doc.id, ...docData });
        });

        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, [agency]);

  const handleChangeStatus = async (id, status) => {
    const docRef = doc(db, "Workorders", "Agencies", "Chestertown", id);
    // the four statuses are ACTIVE, DEMO, PAST DUE, and CLOSED
    // toggle to the next status in the list
    const nextStatus =
      status === "OPEN"
        ? "IN PROGRESS"
        : status === "IN PROGRESS"
        ? "DELAYED"
        : status === "DELAYED"
        ? "COMPLETED"
        : "OPEN";
    await updateDoc(docRef, { status: nextStatus });
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/workorders/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">EDIT</div>
            </Link>
            <div
              className={`viewButton ${params.row.status}Button`}
              onClick={() =>
                handleChangeStatus(params.row.id, params.row.status)
              }
            >
              {params.row.status}
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Workorders
        <Link to="/workorders/new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={workorderColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};


export default WorkorderTable;
