import { ElementStore, ReactFormBuilder, Registry } from "react-form-builder2";
import { Helmet } from "react-helmet";
import "./editBuilder.scss";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import {
  doc,
  addDoc,
  serverTimestamp,
  collection,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { Link } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { PassFailComponent } from "../builder/passFailComponent";
import { CommentComponent } from "../builder/commentComponent";
import { SealComponent } from "../builder/sealComponent";
import { mergePartsIntoSealComponent, replaceSealComponentIntoParts } from "../../../convertSealComponent";


const EditBuilder = ({ rigCheckId, agency }) => {
  if (window.__isReactDndBackendSetUp) {
    window.__isReactDndBackendSetUp = false;
  }
  const [formData, setFormData] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);
  ElementStore.subscribe((state) => {
    setFormData(state.data);
  });
  const onPost = (data) => {
    return;
  };

  useEffect(() => {
    const getRigcheck = async () => {
      const docRef = doc(
        db,
        `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`,
        rigCheckId
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const data = docSnap.data();
        setName(data.name);
        setDescription(data.description);
        const updatedForm = replaceNullWithUndefined(data.form); // likely not needed

        // convert multi part seal components into seal components
        console.log(JSON.parse(updatedForm));
        
        const sealAdjustedForm = mergePartsIntoSealComponent(JSON.parse(updatedForm));
        setFormData(sealAdjustedForm);
        setLoading(false);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setLoading(false);
      }
    };
    getRigcheck();
  }, []);

  function handleInputChange(e) {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else {
      setDescription(value);
    }
  }

  function replaceUndefinedWithNull(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined) {
        obj[key] = null;
      } else if (typeof obj[key] === "object") {
        replaceUndefinedWithNull(obj[key]);
      }
    });

    return obj;
  }

  function replaceNullWithUndefined(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        obj[key] = undefined;
      } else if (typeof obj[key] === "object") {
        replaceNullWithUndefined(obj[key]);
      }
    });

    return obj;
  }

  const onPostHandler = async () => {
    const updatedForm = replaceUndefinedWithNull(formData);

    // if there are multiple elements with "text" value as "name", toasts a warning message and returns
    const nameElements = updatedForm.filter((element) => element.text === "Name");
    if (nameElements.length > 1) {
      toast.warn("Only one name field is allowed", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    // convert seal components back into the multi components
    const formDataCopy = JSON.parse(JSON.stringify(updatedForm));
    const sealAdjustedForm = replaceSealComponentIntoParts(formDataCopy);
    try {
      await updateDoc(
        doc(
          db,
          `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`,
          rigCheckId
        ),
        {
          form: JSON.stringify(sealAdjustedForm),
          name,
          description,
        }
      );
      console.log("Document successfully updated!");
      toast.success("Form Saved Successfully", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error updating document: ", error);
      toast.warn("Error Saving Form", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  try {
    Registry.register("PassFailComponent", PassFailComponent);
    Registry.register("CommentComponent", CommentComponent);
    Registry.register("SealComponent", SealComponent);

  } catch (error) {}
  const items = [
    {
      key: "PassFailComponent",
      element: "CustomElement",
      component: PassFailComponent,
      type: "custom",
      forwardRef: true,
      bare: true,
      field_name: "my_input_",
      name: "Quick Check Item",
      icon: "fa fa-check",
      props: { default: "pass" },
      label: "Pass Fail",
    },
    {
      key: "PassFailComponent",
      element: "CustomElement",
      component: PassFailComponent,
      type: "custom",
      forwardRef: true,
      bare: true,
      field_name: "my_input_",
      name: "Rigcheck Item",
      icon: "fa fa-check",
      label: "Pass Fail",
    },
    {
      key: 'SealComponent',
      element: 'CustomElement',
      component: SealComponent,
      type: 'custom',
      forwardRef: true,
      bare: true,
      // props: { disabled: true }, // test
      name: 'Seal',
      icon: 'fas fa-list-ol',
      label: 'Seal Number',
      field_name: 'seal_',
      defaultValue: 'Seal',
    },
    {
      key: "TextInput",
      name: "Comments",
      icon: "fa fa-comment-dots",
      label: "Comments",
      defaultValue: "DFdaf",
    },
    {
      key: "TextInput",
      name: "Name",
      icon: "fa fa-user",
      label: "Name",
    },
    {
      key: "Header",
    },
    {
      key: "TextInput",
    },
    {
      key: "TextArea",
    },
    {
      key: "Paragraph",
    },
    {
      key: "RadioButtons",
    },
    {
      key: "Checkboxes",
    },
    {
      key: "Dropdown",
    },
    {
      key: "Signature",
    },
  ];

  return (
    <div className="builder-padding">
      <Helmet>
        {" "}
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        />{" "}
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.13.0/css/all.css"
        />{" "}
      </Helmet>{" "}
      <div className="form-header">
        <div className="name-input">
          <label htmlFor="text">Name</label>
          <input
            type="text"
            id="text"
            placeholder="Form Name"
            name="name"
            value={name}
            onChange={handleInputChange}
          />
        </div>
        <div className="description-input">
          <label htmlFor="text">Description</label>
          <input
            type="text"
            id="text"
            placeholder="Form Description"
            name="description"
            value={description}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="button-container">
        <Button onClick={onPostHandler}>Save</Button>
        <Button>
          <Link to={`/rigchecks`} style={{ textDecoration: "none" }}>
            Cancel
          </Link>
        </Button>
      </div>
      {/* Need to load first or else React Form Builder wont work with saved data */}
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="30"
            visible={true}
          />
        </div>
      ) : (
        <ReactFormBuilder
          onPost={onPost}
          key={1}
          data={formData}
          toolbarItems={items}
        />
      )}
    </div>
  );
};

export default EditBuilder;
