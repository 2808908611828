import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  orderBy,
  query,
  writeBatch,
  limit,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import "./agencyRigcheckList.scss";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import QrDialog from "../../qrDialog/QrDialog";


function AgencyRigchecksList({ agency }) {
  const [checks, setChecks] = useState([]);
  const [isReordering, setIsReordering] = useState(false);
  const [currentQRId, setCurrentQRId] = useState(null);
  const [currentQRName, setCurrentQRName] = useState(null);
  const [open, setOpen] = useState(false);

  const handleToggleDisable = async (id, disabled) => {
      const confirmation = window.confirm(
        "Are you sure you want delete this rigcheck?"
      );
    
      if (confirmation) {
        try {
          await updateDoc(
            doc(db, `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`, id),
            {
              disabled: !disabled,
            }
          );
          setChecks(
            checks.map((item) =>
              item.id === id ? { ...item, disabled: !disabled } : item
            )
          );
        } catch (err) {
          console.log(err);
        }
      }
  };

  const duplicateRigcheck = async (rigcheckData) => {
    const batch = writeBatch(db);

    const colRef = collection(
      db,
      `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`
    );

    const querySnapshot = query(
      colRef,
      orderBy("order", "desc"),
      limit(1)
    );
    
    const snapshot = await getDocs(querySnapshot);
    
    let highestOrderNumber;
    
    if (snapshot.docs.length > 0) {
      // If there is a document, extract its order number
      highestOrderNumber = snapshot.docs[0].data().order;
    } else {
      // Handle the case where no documents are found
      highestOrderNumber = -1;
    }

    const newDocRef = doc(collection(db, `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`));
    const newDocData = {
      ...rigcheckData,
      createdAt: new Date(),
      order: highestOrderNumber + 1,
      notificationEmails: [],
      name: rigcheckData.name + " copy"
    };

    batch.set(newDocRef, newDocData);

    await batch.commit();

    const querySnapshot2 = query(
      colRef,
      orderBy("order", "desc")
    );

    const snapshot2 = await getDocs(querySnapshot2);
    const list = snapshot2.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setChecks(list);

  };

  const reorderCheckItem = async (element, direction) => {
    if (isReordering) return;
    setIsReordering(true);

    const elementIndex = checks.findIndex((item) => item.id === element.id);
    if (!isValidMove(elementIndex, direction)) {
      console.log("Cannot move further in this direction");
      setIsReordering(false);
      return;
    }

    const [updatedChecks, elementPair] = getUpdatedChecksAndElements(
      element,
      elementIndex,
      direction
    );
    await updateDatabaseOrder(elementPair);
    animateElementMovement(elementPair, direction, updatedChecks);
  };

  const isValidMove = (elementIndex, direction) => {
    return (
      !(elementIndex === 0 && direction === "up") &&
      !(elementIndex === checks.length - 1 && direction === "down")
    );
  };

  const getUpdatedChecksAndElements = (element, elementIndex, direction) => {
    const checksCopy = JSON.parse(JSON.stringify(checks));
    const adjacentElementIndex =
      direction === "down" ? elementIndex + 1 : elementIndex - 1;
    const adjacentElement = checksCopy[adjacentElementIndex];

    swapOrderValues(element, adjacentElement);

    checksCopy[elementIndex] = adjacentElement;
    checksCopy[adjacentElementIndex] = element;

    return [checksCopy, { element, adjacentElement }];
  };

  const swapOrderValues = (element1, element2) => {
    const tempOrder = element1.order;
    element1.order = element2.order;
    element2.order = tempOrder;
  };

  const updateDatabaseOrder = async ({ element, adjacentElement }) => {
    const batch = writeBatch(db);
    const docRef1 = getDocumentReference(element.id);
    const docRef2 = getDocumentReference(adjacentElement.id);

    batch.update(docRef1, { order: element.order });
    batch.update(docRef2, { order: adjacentElement.order });

    await batch.commit();
  };

  const getDocumentReference = (elementId) => {
    return doc(
      db,
      `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`,
      elementId
    );
  };

  const animateElementMovement = (elementPair, direction, updatedChecks) => {
    const htmlElement = document.querySelector(
      `[data-id="${elementPair.element.id}"]`
    );
    const otherHtmlElement = document.querySelector(
      `[data-id="${elementPair.adjacentElement.id}"]`
    );

    if (!otherHtmlElement) {
      setChecks(updatedChecks);
      setIsReordering(false);
      return;
    }

    applyCssTransition(htmlElement, otherHtmlElement);
    moveElements(htmlElement, otherHtmlElement, direction);

    setTimeout(() => {
      resetCssStyles(htmlElement, otherHtmlElement);
      setChecks(updatedChecks);
      setIsReordering(false);
    }, 300);
  };

  const applyCssTransition = (element1, element2) => {
    element1.style.transition = "transform 300ms ease-in-out";
    element2.style.transition = "transform 300ms ease-in-out";
  };

  const moveElements = (element1, element2, direction) => {
    const distance = element1.getBoundingClientRect().height; //52

    element1.style.transform = `translateY(${
      direction === "down" ? distance : -distance
    }px)`;
    element2.style.transform = `translateY(${
      direction === "down" ? -distance : distance
    }px)`;
  };

  const resetCssStyles = (element1, element2) => {
    element1.style.transform = "";
    element1.style.transition = "";
    element2.style.transform = "";
    element2.style.transition = "";
  };

  const handleToggleService = async (id, disabledService) => {
    console.log(id, disabledService);
    try {
      await updateDoc(
        doc(db, `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`, id),
        {
          disabledService: !disabledService,
        }
      );
      setChecks(
        checks.map((item) =>
          item.id === id ? { ...item, disabledService: !disabledService } : item
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const generateQR = (id, name) => {
    setCurrentQRId(id);
    setCurrentQRName(name);
    handleClickOpen();
  };

  const checkColumns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "description", headerName: "Description", width: 215 },
    {
      field: "action",
      headerName: "Action",
      width: 575,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/rigchecks/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Edit</div>
            </Link>
            <Link
              to={`/rigchecks/view/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={`/rigchecks/notification-settings/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Notifications</div>
            </Link>
            <div className="viewButton" onClick={() => duplicateRigcheck(params.row)}>
              Duplicate
            </div>
            <div className="viewButton" onClick={() => generateQR(params.row.id, params.row.name)}>
              QR Code
            </div>
            <div
              className={`toggleDisableButton enable`}
              onClick={() =>
                handleToggleDisable(params.row.id, params.row.disabled)
              }
            >
              Delete
            </div>
            <div
              className={`toggleDisableButton ${
                params.row.disabledService ? "enable" : "disable"
              }`}
              onClick={() =>
                handleToggleService(params.row.id, params.row.disabledService)
              }
            >
              {params.row.disabledService ? "Out of Service" : "In Service"}
            </div>
          </div>
        );
      },
    },
    {
      field: "order",
      headerName: "Change Order",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div style={{display:'flex', flexDirection:'column'}}>
              <ArrowUpward
                className="reorderButton"
                onClick={() => reorderCheckItem(params.row, "up")}
              />

              <ArrowDownward
                className="reorderButton"
                onClick={() => reorderCheckItem(params.row, "down")}
              />
            </div>
            
          </div>
        );
      },
    }
  ];

  useEffect(() => {
    async function fetchRigchecks() {
      const agencyRigchecksCollection = collection(
        db,
        `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`
      );

      // Add the orderBy clause here
      const querySnapshot = query(
        agencyRigchecksCollection,
        orderBy("order", "desc")
      );

      const snapshot = await getDocs(querySnapshot);
      const list = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setChecks(list);
      
      // get rid of this later
      if (list.length === 0) {
        console.log("No rigchecks found!"); // meaning order field is not present yet
        // // check if first doc has order field
        const batch = writeBatch(db);
        const snapshot = await getDocs(agencyRigchecksCollection);
        snapshot.docs.forEach((docSnapshot, index) => {
          // Correctly create a reference to the document
          const docRef = doc(db, `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`, docSnapshot.id);

          batch.update(docRef, { order: index });
        });
        await batch.commit();
        const snapshot2 = await getDocs(querySnapshot);
        const list2 = snapshot2.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setChecks(list2);
      }

    }
    fetchRigchecks();
  }, []);

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Agency Rigchecks
        <Link to="/rigchecks/new" className="link">
          Add New
        </Link>
        {/* Assuming you might have an Add New button similar to the user management */}
      </div>
      <>
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
          <DataGrid
            className="datagrid"
            rows={checks.filter(check => !check.disabled)}
            columns={checkColumns}
            pageSize={25}
            rowsPerPageOptions={[9]}
            checkboxSelection
            // other props
          />
        </div>
        <QrDialog
          open={open}
          onClose={handleClose}
          id={currentQRId}
          name={currentQRName}
          agency={agency}
        />
      </>
    </div>
  );
}

export default AgencyRigchecksList;
