import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { db } from "../../../firebase";
import { doc, setDoc } from "firebase/firestore";

const CreateGroup = ({ open, handleClose, agency, getGroups }) => {
  const [groupName, setGroupName] = useState(""); // State to hold the input value

  const handleSubmitNewGroup = async () => {
    if (!groupName) return; // Simple validation

    const docRef = doc(db, 'Agencies', agency, 'Qualifications', groupName);
    const payload = { name: groupName, users: [] };
    await setDoc(docRef, payload);

    getGroups();
    handleClose();
    setGroupName('');
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create a New Qualification Group</DialogTitle>
      <DialogContent>
        <div className="groupInput">
          <label htmlFor="text">Name</label>
          <input
            type="text"
            id="text"
            placeholder="Group Name"
            name="name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmitNewGroup} disabled={!groupName}> Submit </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGroup;
