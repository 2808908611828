import "./agenciesTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getDoc } from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import { agencyColumns } from "../../agenciesTableSource";

const AgenciesTable = ({ feeds }) => {
  const [data, setData] = useState([]);
  const { userData } = useContext(AuthContext);
  const agency = userData?.agency;

  useEffect(() => {
    if (!agency) return;
    console.log(feeds);
    let CRAQuery = query(collection(db, "CRM"));

    const unsub = onSnapshot(
      CRAQuery,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          // convert the start and end date firestore timestamp objects into the string with format "MM/DD/YYYY"
          const docData = doc.data();
          const startDate = docData.startDate.toDate();
          const endDate = docData.endDate.toDate();
          const startDateString = `${
            startDate.getMonth() + 1
          }/${startDate.getDate()}/${startDate.getFullYear()}`;
          const endDateString = `${
            endDate.getMonth() + 1
          }/${endDate.getDate()}/${endDate.getFullYear()}`;
          docData.startDate = startDateString;
          docData.endDate = endDateString;

          list.push({ id: doc.id, ...docData });
        });
        console.log(list);
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, [agency]);

  const handleChangeStatus = async (id, status) => {
    const docRef = doc(db, "CRM", id);
    // the four statuses are ACTIVE, DEMO, PAST DUE, and CLOSED
    // toggle to the next status in the list
    const nextStatus =
      status === "ACTIVE"
        ? "DEMO"
        : status === "DEMO"
        ? "LEAD"
        : status === "LEAD"
        ? "PAST DUE"
        : status === "PAST DUE"
        ? "CLOSED"
        : "ACTIVE";
    await updateDoc(docRef, { status: nextStatus });
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={
                feeds ? `/radiofeeds/${params.row.id}` : `/crm/${params.row.id}`
              }
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">EDIT</div>
            </Link>
            {feeds ? (
              <></>
            ) : (
              <div
                className={`viewButton ${params.row.status}Button`}
                onClick={() =>
                  handleChangeStatus(params.row.id, params.row.status)
                }
              >
                {params.row.status}
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Agency Contracts
        {feeds ? (
          <></>
        ) : (
          <Link to="/crm/new" className="link">
            Add New
          </Link>
        )}
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={agencyColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default AgenciesTable;
