import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useContext, useEffect, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";

const NewDoc = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [data, setData] = useState({
    doclevel: "All",
  });
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [per, setPerc] = useState(null);
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const agency = userData?.agency;
  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      setFileName(file.name);
      const storageRef = ref(storage, "docs/" + name); // Use the unique name here
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.error("Upload failed", error);
          setUploadSuccess(false);
        },
        () => {
          // This function is called when the upload successfully completes
          console.log("Upload successful!");
          setUploadSuccess(true);
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({
              ...prev,
              url: downloadURL,
              path: "docs/" + name,
            }));
          });
        }
      );
    };
    if (file) {
      setUploadSuccess(false);
      uploadFile();
    }
  }, [file]);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const { password, ...userData } = data;

      await addDoc(collection(db, agency + " Library"), {
        ...userData,
        timeStamp: serverTimestamp(),
      });

      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const updateFile = (e) => {
    // if file is pdf
    if (e.target.files[0].type === "application/pdf") {
      setFile(e.target.files[0]);
    } else {
      toast.warn("Only PDFs are accepted", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Upload Document:{" "}
                  <DriveFolderUploadOutlinedIcon className="icon" />
                  {uploadSuccess ? (
                    <div className="successIcon">
                      <CheckCircleOutlineIcon
                        style={{ color: "green", fontSize: "36px" }}
                      />{" "}
                      {fileName}
                    </div>
                  ) : (
                    <></>
                  )}
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => updateFile(e)}
                  style={{ display: "none" }}
                />
              </div>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                  />
                </div>
              ))}

              <button disabled={per == null || per < 100} type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDoc;
