export const workorderInputs = [
  {
    id: "assignment",
    label: "Assignment",
    type: "select",
    placeholder: "Ex..Stryker",
    options: [],
    optionsKey: "groups",
  },
  {
    id: "comments",
    label: "Comments",
    type: "textarea",
  },
  {
    id: "description",
    label: "Brief Description",
    type: "text",
  },
  {
    id: "item",
    label: "Item",
    type: "text",
    placeholder: "Ex..Garage Door",
  },
  {
    id: "opendate",
    label: "Open Date",
    type: "date",
  },
  {
    id: "severity",
    label: "Severity",
    type: "select",
    placeholder: "ex. John Doe",
    options: ["HIGH", "MEDIUM", "LOW"],
  },
  {
    id: "status",
    label: "Status",
    type: "select",
    placeholder: "In Progress",
    options: ["OPEN", "CLOSED", "IN PROGRESS", "DELAYED"],
  },
  {
    id: "submittedBy",
    label: "Submitted By",
    type: "text",
    disabled: true,
  },
];
