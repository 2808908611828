// src/AgencyRigchecksList.js

import React, { useState, useEffect } from "react";
import { collection, doc, getDocs, getDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import "./docList.scss";

function AgencyLibraryList({ agency }) {
  const [checks, setChecks] = useState([]);
  const navigate = useNavigate();

  const viewFile = async (id) => {
    const docRef = doc(db, agency + " Library", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const url = data.url;
      window.open(url, '_blank');
    } else {
      console.log("No such document!");
    }
  };

  const checkColumns = [
    { field: "title", headerName: "Name", width: 200 },
    { field: "doclevel", headerName: "Permissions", width: 200 },
    { field: "description", headerName: "Description", width: 300 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div 
              className="viewFileButton"
              onClick={() => viewFile(params.row.id)}
            >
              View
            </div>
            <div
              className="editButton"
              onClick={() => navigate(`/library/${params.row.id}`)}
            >
              Edit
            </div>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id, params.row.path)} // Make sure 'path' contains the correct path to the file in Firebase Storage
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  async function fetchLibrary() {
    const agencyLibraryCollection = collection(db, agency + " Library");
    const snapshot = await getDocs(agencyLibraryCollection);
    const list = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setChecks(list);
  }

  async function handleDelete(id, filePath) {
    const docRef = doc(db, agency + " Library", id);
    await deleteDoc(docRef)
      .then(() => {
        console.log("Document successfully deleted from Firestore!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });

    // Deleting the file from Firebase Storage
    const fileRef = ref(storage, filePath);
    await deleteObject(fileRef)
      .then(() => {
        console.log("File successfully deleted from Storage!");
      })
      .catch((error) => {
        console.error("Error removing file: ", error);
      });

    // Fetch the updated list after deletion
    fetchLibrary();
  }

  useEffect(() => {
    fetchLibrary();
  }, []);

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Agency Library
        <Link to="/library/new" className="link">
          Add New
        </Link>
        {/* Assuming you might have an Add New button similar to the user management */}
      </div>
      <DataGrid
        className="datagrid"
        rows={checks} // Using your checks data directly
        columns={checkColumns} // The columns we defined earlier
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
}

export default AgencyLibraryList;
