import "./radiofeeds.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import AgenciesTable from "../../components/agenciesTable/agenciesTable";

const Radiofeeds = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <AgenciesTable feeds={true} />
      </div>
    </div>
  );
};

export default Radiofeeds;
