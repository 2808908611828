export const docInputs = [
  {
    id: "title",
    label: "Document Name",
    type: "text",
    placeholder: "Ex: Mutual Aid Policy",
  },
  {
    id: "description",
    label: "Description",
    type: "text",
    placeholder: "Enter your document description",
  },
  {
    id: "doclevel",
    label: "Document Permissions",
    type: "text",
    placeholder: "All"
  },
];
