import { ElementStore, ReactFormBuilder, Registry } from "react-form-builder2";
import Navbar from "../../navbar/Navbar";
import Sidebar from "../../sidebar/Sidebar";
import { Helmet } from "react-helmet";
import "./builder.scss";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { doc, addDoc, serverTimestamp, collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { PassFailComponent } from "./passFailComponent";
import { CommentComponent } from "./commentComponent";
import { SealComponent } from "./sealComponent";
import { SealedBrokenComponent } from "./sealedBrokenComponent";
import { ReplaceQuestionComponent } from "./replaceQuestionComponent";
import { SealNumberComponent } from "./sealNumberComponent";
import { replaceSealComponentIntoParts } from "../../../convertSealComponent";

const Builder = ({ agency }) => {
  console.log(agency);
  const navigate = useNavigate();
  if (window.__isReactDndBackendSetUp) {
    window.__isReactDndBackendSetUp = false;
  }
  const [form, setFormData] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  ElementStore.subscribe((state) => {
    setFormData(state.data);
  });
  const onPost = (data) => {
    return;
  };

  function handleInputChange(e) {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
      console.log(name);
    } else {
      setDescription(value);
    }
  }

  function replaceUndefinedWithNull(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined) {
        obj[key] = null;
      } else if (typeof obj[key] === "object") {
        replaceUndefinedWithNull(obj[key]);
      }
    });

    return obj;
  }

  const onPostHandler = async () => {

    const updatedForm = replaceUndefinedWithNull(form);

    // if there are multiple elements with "text" value as "name", toasts a warning message and returns
    const nameElements = updatedForm.filter((element) => element.text === "Name");
    if (nameElements.length > 1) {
      toast.warn("Only one name field is allowed", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const colRef = collection(
      db,
      `Rigchecks/VgTv80ik2BtavT0A5owc/${agency} Rigchecks`
    );

    const querySnapshot = query(
      colRef,
      orderBy("order", "desc"),
      limit(1)
    );
    
    const snapshot = await getDocs(querySnapshot);
    
    let highestOrderNumber;
    
    if (snapshot.docs.length > 0) {
      // If there is a document, extract its order number
      highestOrderNumber = snapshot.docs[0].data().order;
    } else {
      // Handle the case where no documents are found
      highestOrderNumber = -1;
    }

    const sealAdjustedForm = replaceSealComponentIntoParts(updatedForm);
    try {
      const docRef = await addDoc(colRef, {
        form: JSON.stringify(sealAdjustedForm),
        createdAt: serverTimestamp(),
        name,
        description,
        disabledService: false,
        disabled: false,
        notificationEmails: [],
        order: highestOrderNumber + 1,
      });
      // console.log("Document written with ID: ", docRef.id);
      toast.success("Form Saved Successfully", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate(-1);
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.warn("Error Saving Form", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    // got the form data, time to save to firebase
  };
  try {
    Registry.register("PassFailComponent", PassFailComponent);
    Registry.register("CommentComponent", CommentComponent);
    Registry.register("SealComponent", SealComponent);
    Registry.register("SealedBrokenComponent", SealedBrokenComponent);
    Registry.register("ReplaceQuestionComponent", ReplaceQuestionComponent);
    Registry.register("SealNumberComponent", SealNumberComponent);

  } catch (error) {}
  const items = [
    {
      key: "PassFailComponent",
      element: "CustomElement",
      component: PassFailComponent,
      type: "custom",
      forwardRef: true,
      bare: true,
      field_name: "my_input_",
      name: "Quick Check Item",
      icon: "fa fa-check",
      props: { default: "pass" },
      label: "Pass Fail",
    },
    {
      key: "PassFailComponent",
      element: "CustomElement",
      component: PassFailComponent,
      type: "custom",
      forwardRef: true,
      bare: true,
      field_name: "my_input_",
      name: "Rigcheck Item",
      icon: "fa fa-check",
      label: "Pass Fail",
    },
    {
      key: 'SealComponent',
      element: 'CustomElement',
      component: SealComponent,
      type: 'custom',
      forwardRef: true,
      bare: true,
      // props: { disabled: true }, // test
      name: 'Seal',
      icon: 'fas fa-list-ol',
      label: 'Seal Number',
      field_name: 'seal_',
      defaultValue: 'Seal',
    },
    {
      key: "TextInput",
      name: "Comments",
      icon: "fa fa-comment-dots",
      label: "Comments",
    },
    {
      key: "TextInput",
      name: "Name",
      icon: "fa fa-user",
      label: "Name",
    },
    {
      key: "Header",
    },
    {
      key: "TextInput",
    },
    {
      key: "TextArea",
    },
    {
      key: "Paragraph",
    },
    {
      key: "RadioButtons",
    },
    {
      key: "Checkboxes",
    },
    {
      key: "Dropdown",
    },
    {
      key: "Signature",
    },
  ];
  return (
    <div className="builder-padding">
      <Helmet>
        {" "}
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        />{" "}
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.13.0/css/all.css"
        />{" "}
      </Helmet>{" "}
      <div className="form-header">
        <div className="name-input">
          <label htmlFor="text">Name</label>
          <input
            type="text"
            id="text"
            placeholder="Form Name"
            name="name"
            onChange={handleInputChange}
          />
        </div>
        <div className="description-input">
          <label htmlFor="text">Description</label>
          <input
            type="text"
            id="text"
            placeholder="Form Description"
            name="description"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="button-container">
        <Button onClick={onPostHandler}>Save</Button>
        <Button>
          <Link to={`/rigchecks`} style={{ textDecoration: "none" }}>
            Cancel
          </Link>
        </Button>
      </div>
      <ReactFormBuilder onPost={onPost} key={1} toolbarItems={items} />
    </div>
  );
};

export default Builder;